import React from 'react';
import { Route, Redirect, Switch } from 'react-router';

import {
  BetaReportsPageResolver,
  CommunityInsightsPageResolver,
  DisposalSummaryPageResolver,
  FaultCodeDetailsPageResolver,
  InsightDashboardPreferencesPageResolver,
  InsightsFleetPageResolver,
  InsightsWasteAuditPageResolver,
  ReportingDataTrendsPageResolver,
  ReportingDetailsPageResolver,
} from './pages';
import { INSIGHTS_SEE_DETAILS, INSIGHTS_PREFERENCES } from '../../account/constants';
import { RestrictedRoute } from '../../account/components';

const Insights: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/insights/insightsfleet" component={InsightsFleetPageResolver} />
      <Route exact path="/insights/wasteaudit" component={InsightsWasteAuditPageResolver} />

      <RestrictedRoute
        permission={INSIGHTS_PREFERENCES}
        exact
        path="/insights/settings"
        component={InsightDashboardPreferencesPageResolver}
      />
      <RestrictedRoute
        permission={INSIGHTS_SEE_DETAILS}
        exact
        path="/insights/reporting-details"
        component={ReportingDetailsPageResolver}
      />
      <RestrictedRoute
        permission={INSIGHTS_SEE_DETAILS}
        exact
        path="/insights/fault-code-details"
        component={FaultCodeDetailsPageResolver}
      />
      <RestrictedRoute
        permission={INSIGHTS_SEE_DETAILS}
        exact
        path="/insights/disposal-summary"
        component={DisposalSummaryPageResolver}
      />

      <Route exact path="/insights/reporting-data-trends" component={ReportingDataTrendsPageResolver} />

      <Route exact path="/insights/community-insights" component={CommunityInsightsPageResolver} />

      <Route exact path="/insights/reports" component={BetaReportsPageResolver} />
      <Redirect from="/insights/beta-reports" to="/insights/reports" />

      <Redirect from="/insights/route-summary" to="/routes/route-tracker" />
      <Redirect to="/insights/insightsfleet" />
    </Switch>
  );
};

export default Insights;
