import { connect } from 'react-redux';
import { PureComponent } from 'react';
import { size } from 'lodash-es';
import Cookie from 'js-cookie';

import { AppState } from '../../../store';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { currentUserIdSelector, currentVendorIdSelector } from '../../services/currentVendorSelector';
import { DISPATCH_BOARD_VEHICLE_TYPE_COOKIE } from '../../../routes/constants';
import { doLoginActions, User, UserLoginData } from 'src/account/ducks/login';
import { getShouldRedirectToRouteware } from 'src/core/services/environment';
import { Grid, GridColumn } from '../../../core/components/styled';
import { HAULER_PROFILE_DIVISION_COOKIE } from '../../../haulerProfile/constants/HaulerProfileDivisionCookie';
import { loadDispatchOpportunitiesCount } from '../../../common/ducks';
import { login, loginWithSSOToken, updateTokerSuperAdmin } from 'src/account/ducks';
import { Modal } from '../../../core/components';
import { SelectDefaultVendorForm } from '../forms';
import { SELECTED_VENDOR_ID_COOKIE, SELECTED_VENDOR_ID_COOKIE_EXPIRATION } from 'src/common/constants';
import { setDefaultVendor } from '../../ducks';
import { UseIsMobileWidthView } from 'src/core/components/mediaQueries/MobileWidthView';
import { Vendor } from 'src/vendors/interfaces/Vendors';
import defaultVendorFormInitialValuesSelector from '../../services/defaultVendorFormInitialValuesSelector';
import translate from '../../../core/services/translate';

interface Props {
  closeModal?: () => void;
  doLoginActions: (user: User, redirect?: string) => void;
  formInitialValues: any;
  isSettingDefaultVendor: boolean;
  login: (userEmail?: string, userPassword?: string, userRedirectTo?: string, vendorId?: number) => Promise<any>;
  loginWithSSOToken: (ssoToken: string, userRedirectTo?: string, vendorId?: number) => Promise<any>;
  loginData?: UserLoginData;
  onDefaultVendorSelected: (vendorId: number) => void;
  setDefaultVendor: (vendorId: number) => Promise<any>;
  shouldRefreshPage?: boolean;
  updateTokerSuperAdmin: (vendorId?: number) => Promise<any>;
  vendorId?: number;
  vendorList?: Vendor[];
  ssoAuth?: string;
}

interface State {
  defaultVendorChanged: boolean;
}

class SelectDefaultVendorModal extends PureComponent<Props, State> {
  static defaultProps = {
    shouldRefreshPage: true,
  };

  state = {
    defaultVendorChanged: false,
  };

  onSubmit = async ({ vendorId }: any) => {
    const { defaultVendorChanged } = this.state;
    const {
      closeModal,
      doLoginActions,
      login,
      loginWithSSOToken,
      loginData,
      onDefaultVendorSelected,
      setDefaultVendor,
      shouldRefreshPage,
      updateTokerSuperAdmin,
      vendorList,
      ssoAuth,
    } = this.props;

    const doLogin = async (user: User, vendorId: number, shouldRefreshPage?: boolean) => {
      await setDefaultVendor(vendorId);
      onDefaultVendorSelected(vendorId);
      createSuccessNotification(
        translate(`defaultVendor.alertMessages.${shouldRefreshPage ? 'vendorChanged' : 'vendorSelected'}`),
      );

      Cookie.remove(DISPATCH_BOARD_VEHICLE_TYPE_COOKIE);
      Cookie.remove(HAULER_PROFILE_DIVISION_COOKIE);
      Cookie.set(SELECTED_VENDOR_ID_COOKIE, vendorId.toString(), { expires: SELECTED_VENDOR_ID_COOKIE_EXPIRATION });

      doLoginActions(user);
      closeModal && closeModal();
    };

    const redirectToRouteware = vendorList?.find(vendor => vendor.id === vendorId)?.redirectToRouteware || false;
    const redirectData = getShouldRedirectToRouteware(redirectToRouteware);

    if (redirectData.shouldRedirect) {
      window.location.href = redirectData.newDomainURL;
    } else {
      if (shouldRefreshPage) {
        if (defaultVendorChanged) {
          updateTokerSuperAdmin(vendorId)
            .then((user: User) => {
              doLogin(user, vendorId, shouldRefreshPage);
            })
            .catch(() => {
              createErrorNotification(translate('defaultVendor.alertMessages.errorMessage'));
            });
        } else {
          closeModal && closeModal();
        }
      } else if (!!size(vendorList) && vendorId) {
        if (ssoAuth) {
          loginWithSSOToken(ssoAuth, loginData && loginData.userRedirectTo, vendorId).then((user: User) => {
            if (defaultVendorChanged) {
              doLogin(user, vendorId);
            } else {
              closeModal && closeModal();
            }
          });
        } else {
          login(
            loginData && loginData.userEmail,
            loginData && loginData.userPassword,
            loginData && loginData.userRedirectTo,
            vendorId,
          ).then((user: User) => {
            if (defaultVendorChanged) {
              doLogin(user, vendorId);
            } else {
              closeModal && closeModal();
            }
          });
        }
      }
    }
  };

  onDefaultVendorChange = (value: number) => {
    const { vendorId } = this.props;
    this.setState({ defaultVendorChanged: value !== vendorId });
  };

  stopPropagation = (event: Event) => {
    event.stopPropagation();
  };

  render() {
    const { isSettingDefaultVendor, formInitialValues, closeModal, vendorList } = this.props;

    return (
      <UseIsMobileWidthView
        render={isMobile => (
          <Modal
            overflow={isMobile ? 'visible' : undefined}
            onClose={closeModal}
            size="small"
            verticalSize={isMobile ? undefined : 'smallMedium'}
            isLoading={isSettingDefaultVendor}
            title={translate('defaultVendor.pleaseSelectVendor')}
            subTitle={translate('defaultVendor.vendorWillBeUsedAsDefault')}
          >
            <Grid centered>
              <GridColumn size={isMobile ? '12/12' : '8/12'}>
                <SelectDefaultVendorForm
                  initialValues={formInitialValues}
                  onSubmit={this.onSubmit}
                  onDefaultVendorChange={this.onDefaultVendorChange}
                  vendorList={vendorList}
                />
              </GridColumn>
            </Grid>
          </Modal>
        )}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  formInitialValues: defaultVendorFormInitialValuesSelector(state.vendors.defaultVendor),
  isSettingDefaultVendor: state.vendors.defaultVendor.isSettingDefaultVendor,
  userId: (currentUserIdSelector as any)(state.account.login, state.vendors.defaultVendor),
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  doLoginActions,
  loadDispatchOpportunitiesCount,
  login,
  loginWithSSOToken,
  setDefaultVendor,
  updateTokerSuperAdmin,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectDefaultVendorModal);
