import React, { PureComponent } from 'react';

import { change, Field, formValueSelector, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { debounce, find, filter, map, size } from 'lodash-es';
import Cookie from 'js-cookie';
import moment from 'moment';

import { DailyReportTypeDropdown, VehicleTypesForVendorMultiSelect } from '..';
import { loadServiceTypes, loadWasteTypes } from '../../../common/ducks';
import { DuckFunction } from '../../../contracts/ducks';
import {
  DatePicker,
  DateRangePicker,
  Input,
  MultiSelect,
  TypeAhead,
  UnconnectedCheckbox,
} from '../../../core/components';
import { DateRangeOptionValue, getMaxSearchableMonths } from '../../../core/components/DateRangePicker';
import { Button, Grid, GridColumn, PanelSection, PanelSectionGroup, Text } from '../../../core/components/styled';
import {
  DATE_RANGE_PICKER_30_60_90,
  DATE_RANGE_PICKER_TODAY_3_7_PAST,
  GET_MONTHS_BEFORE,
  TODAY,
  TODAY_FORMATTED,
  YESTERDAY,
} from '../../../core/constants/weekdays';
import translate from '../../../core/services/translate';
import { ROUTE } from '../../../fleet/constants';
import { AppState } from '../../../store';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import { isDateValidValidator, isEmail, isRequired } from '../../../utils/services/validator';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { INSIGHTS_COOKIE_DATE_KEY, INSIGHTS_COOKIE_START_DATE_KEY, MAX_REPORT_RECIPIENTS } from '../../constants';
import {
  ACTIVITY_BY_ACCOUNTING_CODE,
  ACTIVITY_REPORT,
  AGING_REPORT,
  BILLING_REPORT,
  CART,
  COLLECTIONS_REPORT,
  COMMUNITY_PARTICIPATION,
  CUSTOMER_EXPORT,
  DAILY,
  DELIVERY_UTILITY_JOB_HISTORY,
  DEPOSIT_SLIP_REPORT,
  DISPOSAL_TONS,
  DRIVER_PRODUCTIVITY,
  DRIVER_SAFETY_INSPECTION,
  DRIVER_SUMMARY,
  EXCEPTIONS,
  FAULT_CODES,
  FUEL_LOG,
  INSIGHT_HISTORY,
  LOCATION_ALERTS,
  MULTIPLE_DAY_WEB_PAYMENT_REPORT,
  NEW_SERVICES_AUDIT_REPORT,
  ORPHAN_INVOICE_REPORT,
  PAYMENT_REPORT,
  PRE_POST_TRIP_INSPECTION,
  RESIDENTIAL_STOP,
  ROLL_OF_SERVICE_HISTORY,
  ROLL_OFF_JOB_HISTORY,
  ROUTE_EXCEPTION, //PICKUP REPORT
  ROUTE_PRODUCTIVITY,
  ROUTE_SUMMARY,
  SAFETY,
  SINGLE_DAY_WEB_PAYMENT_REPORT,
  SNOW_ROUTE_SUMMARY,
  STREET_SWEEPER_ROUTE_SUMMARY,
  STREET_SWEEPING_ACTIVITY_SUMMARY,
  STREET_SWEEPING_OBSTACLES,
  UNSERVICED_RESIDENTIAL,
  USER_ACTIVITY,
  Y_DEVICE_STATUS,
} from '../../constants/reportTypes';

import { VEHICLE_TYPE_IDS } from 'src/fleet/constants';
import loadRoutes from '../../services/loadRoutes';
import { EmailRecipient, EmailRecipientRemove, EmailRecipientRemoveIcon } from '../styled/EmailRecipient';

import {
  getVehicleFiltersPreferencesIds,
  getAllFiltersPreferencesIds,
  getExcludeVehicleFilters,
  getExcludedFiltersIds,
} from 'src/common/utils/filters';
import { FilterSetting } from 'src/vendors/interfaces/Filters';
import { FILTER_SERVICE_TYPE_ID, SEARCH_BY_NAME_OR_ADDRESS } from 'src/common/constants';
import { SERVICE_HISTORY } from 'src/insights/constants/dailyReportTypes';
import { loadCustomersSimplified } from 'src/customers/ducks';
import { Customer } from 'src/customers/interfaces/Customers';
import { MAX_LIMIT_PER_PAGE } from 'src/core/constants/pagination';
import { BUSINESS_TYPE_ID, STREET_TYPE_ID } from 'src/customers/constants/customerTypes';
import { ACTIVE, NEW, ON_HOLD, SUSPENDED, CLOSED } from 'src/common/constants/accountStatuses';
import { loadVehicles } from 'src/fleet/ducks';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { DataRetention } from 'src/vendors/interfaces/DataRetention';

const getMultiSelectText = (
  selectedOptions: string[],
  allOptionsSelected: boolean,
  allText: string,
  seletedText: string,
  textTranslation?: string,
) => {
  if (allOptionsSelected) {
    return translate(allText);
  }
  return size(selectedOptions) === 1
    ? textTranslation
      ? translate(createTranslationKey(selectedOptions[0], textTranslation))
      : selectedOptions[0]
    : translate(seletedText, { selected: size(selectedOptions) });
};

const getVehiclesText = (selectedOptions: string[], allOptionsSelected: boolean) => {
  return getMultiSelectText(selectedOptions, allOptionsSelected, 'vehicles.allVehicles', 'vehicles.xVehiclesSelected');
};

const getWasteTypesText = (selectedOptions: string[], allOptionsSelected: boolean) => {
  return getMultiSelectText(
    selectedOptions,
    allOptionsSelected,
    'insights.allWasteTypes',
    'insights.xWasteTypesSelected',
  );
};

const getServiceTypesText = (selectedOptions: string[], allOptionsSelected: boolean) => {
  return getMultiSelectText(
    selectedOptions,
    allOptionsSelected,
    'insights.allServiceTypes',
    'insights.xServiceTypesSelected',
  );
};

const getVehicleTypesText = (selectedOptions: string[], allOptionsSelected: boolean) => {
  return getMultiSelectText(
    selectedOptions,
    allOptionsSelected,
    'vehicles.allVehicleTypes',
    'vehicles.xVehicleTypesSelected',
    'vehicles.vehicleTypes',
  );
};

const getDriversText = (selectedOptions: string[], allOptionsSelected: boolean) => {
  return getMultiSelectText(selectedOptions, allOptionsSelected, 'drivers.allDrivers', 'drivers.xDriversSelected');
};

const getUsersText = (selectedOptions: string[], allOptionsSelected: boolean) => {
  return getMultiSelectText(selectedOptions, allOptionsSelected, 'vendors.allUsers', 'vendors.xUsersSelected');
};

const getRoutesNameText = (selectedOptions: string[], allOptionsSelected: boolean) => {
  return getMultiSelectText(selectedOptions, allOptionsSelected, 'insights.allRoutes', 'insights.xRoutesSelected');
};

const DATE_RANGE_6_DAYS = 6;
const DATE_RANGE_30_DAYS = 30;
const DATE_RANGE_90_DAYS = 90;
const DATE_RANGE_120_DAYS = 120;
const DATE_RANGE_185_DAYS = 185;
const DATE_RANGE_365_DAYS = 365;

interface Props {
  accountNumber?: string;
  change: any;
  currentAccountEmail: string;
  customerIds?: number[];
  customerName?: string;
  dataRetention: DataRetention[];
  drivers: any[];
  excludeVehicleFilters: string[];
  filtersPreferences?: FilterSetting[];
  filtersPreferencesIds: any;
  formValues?: any;
  getEmailAddresses: any;
  loadCustomersSimplified: DuckFunction<typeof loadCustomersSimplified>;
  loadServiceTypes: DuckFunction<typeof loadServiceTypes>;
  loadRoutes: (
    vendorId: number,
    reportType?: string,
    vehicleType?: string,
    vehicleName?: string,
    routeName?: string,
    driverName?: string,
    fromDate?: string,
    to?: string,
    filtersPreferencesIds?: any,
  ) => {};
  loadVehicles: DuckFunction<typeof loadVehicles>;
  loadWasteTypes: DuckFunction<typeof loadWasteTypes>;
  push: (url: string) => void;
  searchTerm?: string;
  serviceTypes: any[];
  users: any[];
  vehicleFiltersPreferencesName: string[];
  vehicles: any[];
  vendorId: number;
  wasteTypes: any[];
}

interface State {
  customers: Customer[];
  emailAddresses: string[];
  isAllCustomersChecked?: boolean;
  isWeekendOnlyChecked?: boolean;
  isAllVehiclesLoaded?: boolean;
  isChecked?: boolean;
  routeNames: any[];
  validationMessage?: string;
}

type ComponentProps = Props & InjectedFormProps<any, Props>;

class BetaReportsForm extends PureComponent<ComponentProps, State> {
  readonly state: State = {
    customers: [],
    emailAddresses: [],
    isAllCustomersChecked: true,
    isWeekendOnlyChecked: false,
    isAllVehiclesLoaded: true,
    isChecked: false,
    routeNames: [],
    validationMessage: '',
  };

  setDateRangeValue = (from?: string, to?: string) => {
    const { change } = this.props;
    change('dateRange', {
      from,
      to,
    });
  };

  componentDidUpdate() {
    this.props.getEmailAddresses(this.state.emailAddresses);
  }

  onReportTypeChange = (isDateDisabled: boolean, reportType: string) => {
    const { change, formValues, loadServiceTypes, loadVehicles, loadWasteTypes, vendorId } = this.props;
    const { isAllVehiclesLoaded } = this.state;

    this.resetFormFields();

    if (reportType === DRIVER_PRODUCTIVITY || reportType === ROUTE_PRODUCTIVITY) {
      loadServiceTypes(formValues.vehicleType);
      loadWasteTypes();
      loadRoutes(vendorId, reportType, '', '', '', '', formValues.dateRange.from, formValues.dateRange.to).then(
        data => {
          this.setState({ routeNames: data });
        },
      );
    }

    const showActiveTrucks = true;
    if (reportType === STREET_SWEEPER_ROUTE_SUMMARY || reportType === SNOW_ROUTE_SUMMARY) {
      const vehicleTypeId = reportType === STREET_SWEEPER_ROUTE_SUMMARY ? STREET_SWEEPER_ID : SNOW_PLOW_ID;
      loadVehicles(vendorId, showActiveTrucks, vehicleTypeId);
      this.setState({ isAllVehiclesLoaded: false });
    } else if (!isAllVehiclesLoaded) {
      loadVehicles(vendorId, showActiveTrucks);
      this.setState({ isAllVehiclesLoaded: true });
    }

    if (isDateDisabled) {
      change('startDate', YESTERDAY);
    } else {
      const insightsDate = Cookie.get(INSIGHTS_COOKIE_DATE_KEY);
      const insightsStartDate = Cookie.get(INSIGHTS_COOKIE_START_DATE_KEY);

      if (formValues && formValues.dateRange) {
        const {
          dateRange: { from, to },
        } = formValues;
        let newToDate;
        const dateDiff = moment(to, 'MM/DD/YYYY').diff(moment(from, 'MM/DD/YYYY'), 'days');
        const addDiff = (diff: number) => moment(from, 'MM/DD/YYYY').add(diff, 'days').format('MM/DD/YYYY');

        if (reportType === STREET_SWEEPING_OBSTACLES || reportType === STREET_SWEEPING_ACTIVITY_SUMMARY) {
          if (dateDiff > DATE_RANGE_6_DAYS) {
            newToDate = addDiff(DATE_RANGE_6_DAYS);
            this.setDateRangeValue(insightsStartDate || insightsDate, newToDate);
          }
        } else if (reportType === EXCEPTIONS) {
          if (dateDiff > DATE_RANGE_30_DAYS) {
            newToDate = addDiff(DATE_RANGE_30_DAYS);
            this.setDateRangeValue(insightsStartDate || insightsDate, newToDate);
          }
        } else if (
          reportType === COMMUNITY_PARTICIPATION ||
          reportType === SERVICE_HISTORY ||
          reportType === DELIVERY_UTILITY_JOB_HISTORY ||
          reportType === DRIVER_SUMMARY ||
          reportType === LOCATION_ALERTS ||
          reportType === ROLL_OFF_JOB_HISTORY ||
          reportType === Y_DEVICE_STATUS
        ) {
          if (reportType === DRIVER_SUMMARY && !insightsDate && !insightsStartDate && to === from) {
            // there was no date set, so we set a default here
            newToDate = addDiff(DATE_RANGE_6_DAYS);
            const newFrom = moment(from, 'MM/DD/YYYY').subtract(DATE_RANGE_6_DAYS, 'days').format('MM/DD/YYYY');
            this.setDateRangeValue(newFrom, to);
          }
          if (dateDiff > DATE_RANGE_90_DAYS) {
            newToDate = addDiff(DATE_RANGE_90_DAYS);
            this.setDateRangeValue(insightsStartDate || insightsDate, newToDate);
          }
        } else {
          this.setDateRangeValue(insightsStartDate || insightsDate || YESTERDAY, insightsDate || YESTERDAY);
        }
      }

      change('startDate', insightsDate || YESTERDAY);
    }
  };

  onVehicleTypeChange = (value: string) => {
    const { vendorId, formValues } = this.props;
    this.setState({ routeNames: [] });

    loadRoutes(
      vendorId,
      formValues.reportType,
      value.toString(),
      formValues.vehicleName,
      '',
      formValues.driverName,
      formValues.dateRange.from,
      formValues.dateRange.to,
    ).then(data => {
      this.setState({ routeNames: data });
    });
  };

  onDriverNameChange = (event: any) => {
    const { vendorId, formValues } = this.props;
    this.setState({ routeNames: [] });

    loadRoutes(
      vendorId,
      formValues.reportType,
      formValues.vehicleType,
      formValues.vehicleName,
      '',
      event.toString(),
      formValues.dateRange.from,
      formValues.dateRange.to,
    ).then(data => {
      this.setState({ routeNames: data });
    });
  };

  resetFormFields = () => {
    const { change } = this.props;
    change('checkBox', false);
    change('recipientEmail', undefined);
    change('vehicleType', undefined);
    change('vehicleName', undefined);
    change('driverName', undefined);
    change('routeName', undefined);
    change('wasteMaterialType', undefined);
    change('serviceType', undefined);
    this.setState({ emailAddresses: [] });
  };

  onDateChanged = (event: any, { from, to }: DateRangeOptionValue) => {
    const eventIsValid = moment(event, 'MM/DD/YYYY', true).isValid();
    const fromIsValid = moment(from, 'MM/DD/YYYY', true).isValid();
    const toIsValid = moment(to, 'MM/DD/YYYY', true).isValid();

    if (eventIsValid || fromIsValid) Cookie.set(INSIGHTS_COOKIE_START_DATE_KEY, from && fromIsValid ? from : event);
    if (eventIsValid || toIsValid) Cookie.set(INSIGHTS_COOKIE_DATE_KEY, to && toIsValid ? to : event);
  };

  onSingleDateChanged = (event: any) => {
    const eventIsValid = moment(event, 'MM/DD/YYYY', true).isValid();

    if (eventIsValid) {
      Cookie.set(INSIGHTS_COOKIE_START_DATE_KEY, event);
      Cookie.set(INSIGHTS_COOKIE_DATE_KEY, event);
    }
  };

  loadRoutesOptions = debounce((routeName, onOptionsLoaded) => {
    if (routeName.trim().length < 3) {
      onOptionsLoaded([]);
      return;
    }

    const {
      dateRange: { from, to },
      driverName,
      reportType,
      startDate,
      vehicleName,
      vehicleType,
    } = this.props.formValues;
    const { vendorId } = this.props;

    const isSingleDateDisplayed =
      reportType === AGING_REPORT ||
      reportType === COLLECTIONS_REPORT ||
      reportType === DAILY ||
      reportType === DRIVER_SAFETY_INSPECTION ||
      reportType === ORPHAN_INVOICE_REPORT ||
      reportType === PRE_POST_TRIP_INSPECTION ||
      reportType === ROLL_OF_SERVICE_HISTORY ||
      reportType === UNSERVICED_RESIDENTIAL ||
      reportType === Y_DEVICE_STATUS;

    loadRoutes(
      vendorId,
      reportType,
      vehicleType,
      vehicleName,
      routeName,
      driverName,
      isSingleDateDisplayed ? startDate : from,
      isSingleDateDisplayed ? startDate : to,
    ).then(onOptionsLoaded);
  }, 500);

  loadCustomersOptions = debounce((searchTerm, onOptionsLoaded) => {
    const customerTypeIds = `${ACTIVE}, ${NEW}, ${SUSPENDED}, ${ON_HOLD}, ${CLOSED},`;
    const customerStatusTypeIds = `${BUSINESS_TYPE_ID}, ${STREET_TYPE_ID}`;
    const sortedBy = 'name';
    const sortOrder = 'asc';
    const { loadCustomersSimplified, vendorId } = this.props;

    if (!vendorId || searchTerm.trim().length < 3) {
      onOptionsLoaded([]);
      return;
    }
    loadCustomersSimplified({
      vendorId,
      searchTerm,
      customerTypeIds,
      customerStatusTypeIds,
      page: 1,
      limit: MAX_LIMIT_PER_PAGE,
      sortedBy,
      sortOrder,
      searchType: SEARCH_BY_NAME_OR_ADDRESS,
    }).then(
      res =>
        res &&
        onOptionsLoaded(
          res.customers.map((customer: Customer) => {
            return { value: customer.id, label: customer.name };
          }),
        ),
    );
  }, 500);

  loadCustomerLocationOptions = debounce((searchTerm, onOptionsLoaded) => {
    const customerTypeIds = undefined;
    const customerStatusTypeIds = undefined;
    const page = undefined;
    const limit = undefined;
    const sortedBy = undefined;
    const { loadCustomersSimplified, vendorId, formValues } = this.props;
    const { customerId } = formValues;

    if (searchTerm.trim().length < 3) {
      onOptionsLoaded([]);
      return;
    }

    loadCustomersSimplified({
      vendorId,
      searchTerm,
      customerTypeIds,
      customerStatusTypeIds,
      page,
      limit,
      sortedBy,
      searchType: SEARCH_BY_NAME_OR_ADDRESS,
    }).then(res => {
      const customerLocations = map(
        filter(res.customers, (customer: Customer) => customer.id === customerId),
        (customer: Customer) =>
          map(customer.locations, location => ({
            value: { locationId: location.id, locationName: location.name },
            label: location.name,
          })),
      );

      return onOptionsLoaded(...customerLocations);
    });
  }, 500);

  getDriversText = (selectedOptions: string[], allOptionsSelected: boolean) => {
    const { drivers } = this.props;

    return size(selectedOptions) === 1
      ? find(drivers, { id: Number(selectedOptions[0]) })?.name
      : getMultiSelectText(selectedOptions, allOptionsSelected, 'drivers.allDrivers', 'drivers.xDriversSelected');
  };

  getUsersText = (selectedOptions: string[], allOptionsSelected: boolean) => {
    const { users } = this.props;

    return size(selectedOptions) === 1
      ? find(users, { personId: Number(selectedOptions[0]) })?.name
      : getMultiSelectText(selectedOptions, allOptionsSelected, 'drivers.allDrivers', 'drivers.xDriversSelected');
  };

  onCustomerIdsChange = (e: any) => {
    const { change } = this.props;
    if (!!e.length) this.setState({ isAllCustomersChecked: false });
    else if (!e.length) change('customerIds', []);
  };

  addRecipientClickHandler = (e: any) => {
    e.preventDefault();
    const { recipientEmail } = this.props.formValues;
    const { emailAddresses } = this.state;
    const { change } = this.props;

    if (isEmail(recipientEmail) === undefined) {
      if (emailAddresses.includes(recipientEmail)) {
        this.setState({ validationMessage: translate('insights.emailIsAlreadyAdded') });
      } else {
        if (!emailAddresses.length || emailAddresses.length < MAX_REPORT_RECIPIENTS) {
          this.setState({
            validationMessage: '',
            emailAddresses: [...emailAddresses, recipientEmail],
          });
          change('recipientEmail', undefined);
        } else {
          this.setState({ validationMessage: translate('insights.moreThan10EmailAddresses') });
        }
      }
    } else {
      this.setState({ validationMessage: isEmail(recipientEmail) });
    }
  };

  emailToMe = (e: any) => {
    const { currentAccountEmail } = this.props;
    const { emailAddresses } = this.state;

    if (e.target.checked && currentAccountEmail)
      this.setState({ emailAddresses: [...emailAddresses, currentAccountEmail], isChecked: true });
    else
      this.setState({
        emailAddresses: emailAddresses.filter(el => el !== currentAccountEmail),
        isChecked: false,
      });
  };

  render() {
    const {
      change,
      currentAccountEmail,
      dataRetention,
      drivers,
      filtersPreferences,
      formValues,
      handleSubmit,
      serviceTypes,
      users,
      vehicles,
      wasteTypes,
    } = this.props;
    const { reportType, to, recipientEmail, customerId } = formValues;
    const { routeNames, emailAddresses, validationMessage, isChecked, isAllCustomersChecked, isWeekendOnlyChecked } =
      this.state;
    const endDateAsDate = to && moment(to, 'MM/DD/YYYY').toDate();

    // VehicleFilters Preferences
    const excludeVehicleFilters = getExcludeVehicleFilters(filtersPreferences);

    const vehicleOptions = vehicles.map(({ regplate }) => ({
      label: regplate,
      value: regplate,
    }));

    const wasteTypesOptions = wasteTypes.map(({ name, technicalName }) => ({
      label: name,
      value: technicalName,
    }));

    // ServiceTypesFilters Preferences
    const excludeServiceTypes = getExcludedFiltersIds(serviceTypes, filtersPreferences, FILTER_SERVICE_TYPE_ID);

    const serviceTypesOptions = map(
      filter(serviceTypes, serviceType => !excludeServiceTypes.includes(serviceType.id)),
      serviceType => ({
        label: serviceType.name,
        value: serviceType.technicalName,
      }),
    );

    const driversOptions = drivers.map(({ id, name }) => ({
      label: name,
      value: reportType === USER_ACTIVITY ? id : name,
    }));

    const usersOptions = users.map(({ personId, name }) => ({
      label: name,
      value: personId,
    }));

    const routeNameOptions = routeNames.map(({ label, value }) => ({
      label: label,
      value: value,
    }));

    const isVehicleTypesDisabled =
      reportType === AGING_REPORT ||
      reportType === BILLING_REPORT ||
      reportType === CART ||
      reportType === COLLECTIONS_REPORT ||
      reportType === COMMUNITY_PARTICIPATION ||
      reportType === DELIVERY_UTILITY_JOB_HISTORY ||
      reportType === EXCEPTIONS ||
      reportType === FUEL_LOG ||
      reportType === ORPHAN_INVOICE_REPORT ||
      reportType === PAYMENT_REPORT ||
      reportType === ROLL_OF_SERVICE_HISTORY ||
      reportType === ROLL_OFF_JOB_HISTORY ||
      reportType === SERVICE_HISTORY ||
      reportType === SNOW_ROUTE_SUMMARY ||
      reportType === STREET_SWEEPER_ROUTE_SUMMARY ||
      reportType === STREET_SWEEPING_ACTIVITY_SUMMARY ||
      reportType === STREET_SWEEPING_OBSTACLES ||
      reportType === UNSERVICED_RESIDENTIAL ||
      reportType === USER_ACTIVITY ||
      reportType === Y_DEVICE_STATUS ||
      reportType === DEPOSIT_SLIP_REPORT ||
      reportType === ACTIVITY_REPORT ||
      reportType === ACTIVITY_BY_ACCOUNTING_CODE ||
      reportType === MULTIPLE_DAY_WEB_PAYMENT_REPORT ||
      reportType === NEW_SERVICES_AUDIT_REPORT ||
      reportType === SINGLE_DAY_WEB_PAYMENT_REPORT;

    const isVehiclesDisabled =
      reportType === AGING_REPORT ||
      reportType === BILLING_REPORT ||
      reportType === CART ||
      reportType === COLLECTIONS_REPORT ||
      reportType === COMMUNITY_PARTICIPATION ||
      reportType === DELIVERY_UTILITY_JOB_HISTORY ||
      reportType === DRIVER_PRODUCTIVITY ||
      reportType === DRIVER_SUMMARY ||
      reportType === EXCEPTIONS ||
      reportType === LOCATION_ALERTS ||
      reportType === ORPHAN_INVOICE_REPORT ||
      reportType === PAYMENT_REPORT ||
      reportType === ROLL_OF_SERVICE_HISTORY ||
      reportType === ROLL_OFF_JOB_HISTORY ||
      reportType === ROUTE_PRODUCTIVITY ||
      reportType === SERVICE_HISTORY ||
      reportType === USER_ACTIVITY ||
      reportType === Y_DEVICE_STATUS ||
      reportType === DEPOSIT_SLIP_REPORT ||
      reportType === ACTIVITY_REPORT ||
      reportType === ACTIVITY_BY_ACCOUNTING_CODE ||
      reportType === MULTIPLE_DAY_WEB_PAYMENT_REPORT ||
      reportType === NEW_SERVICES_AUDIT_REPORT ||
      reportType === SINGLE_DAY_WEB_PAYMENT_REPORT;

    const isDriversDisabled =
      reportType === AGING_REPORT ||
      reportType === BILLING_REPORT ||
      reportType === CART ||
      reportType === COLLECTIONS_REPORT ||
      reportType === COMMUNITY_PARTICIPATION ||
      reportType === DELIVERY_UTILITY_JOB_HISTORY ||
      reportType === DRIVER_SAFETY_INSPECTION ||
      reportType === EXCEPTIONS ||
      reportType === FAULT_CODES ||
      reportType === FUEL_LOG ||
      reportType === LOCATION_ALERTS ||
      reportType === ORPHAN_INVOICE_REPORT ||
      reportType === PAYMENT_REPORT ||
      reportType === PRE_POST_TRIP_INSPECTION ||
      reportType === ROLL_OF_SERVICE_HISTORY ||
      reportType === ROLL_OFF_JOB_HISTORY ||
      reportType === ROUTE_PRODUCTIVITY ||
      reportType === SERVICE_HISTORY ||
      reportType === STREET_SWEEPING_ACTIVITY_SUMMARY ||
      reportType === STREET_SWEEPING_OBSTACLES ||
      reportType === Y_DEVICE_STATUS ||
      reportType === DEPOSIT_SLIP_REPORT ||
      reportType === ACTIVITY_REPORT ||
      reportType === ACTIVITY_BY_ACCOUNTING_CODE ||
      reportType === MULTIPLE_DAY_WEB_PAYMENT_REPORT ||
      reportType === NEW_SERVICES_AUDIT_REPORT ||
      reportType === SINGLE_DAY_WEB_PAYMENT_REPORT;

    const isUsersVisible = reportType === USER_ACTIVITY;

    const isRouteDisabled =
      reportType === AGING_REPORT ||
      reportType === BILLING_REPORT ||
      reportType === COLLECTIONS_REPORT ||
      reportType === COMMUNITY_PARTICIPATION ||
      reportType === DELIVERY_UTILITY_JOB_HISTORY ||
      reportType === DRIVER_SAFETY_INSPECTION ||
      reportType === DRIVER_SUMMARY ||
      reportType === EXCEPTIONS ||
      reportType === FAULT_CODES ||
      reportType === FUEL_LOG ||
      reportType === LOCATION_ALERTS ||
      reportType === ORPHAN_INVOICE_REPORT ||
      reportType === PAYMENT_REPORT ||
      reportType === PRE_POST_TRIP_INSPECTION ||
      reportType === ROLL_OF_SERVICE_HISTORY ||
      reportType === ROLL_OFF_JOB_HISTORY ||
      reportType === ROUTE_PRODUCTIVITY ||
      reportType === SERVICE_HISTORY ||
      reportType === STREET_SWEEPING_ACTIVITY_SUMMARY ||
      reportType === STREET_SWEEPING_OBSTACLES ||
      reportType === USER_ACTIVITY ||
      reportType === Y_DEVICE_STATUS ||
      reportType === DEPOSIT_SLIP_REPORT ||
      reportType === ACTIVITY_REPORT ||
      reportType === ACTIVITY_BY_ACCOUNTING_CODE ||
      reportType === MULTIPLE_DAY_WEB_PAYMENT_REPORT ||
      reportType === NEW_SERVICES_AUDIT_REPORT ||
      reportType === SINGLE_DAY_WEB_PAYMENT_REPORT;

    const isSingleDateDisplayed =
      reportType === AGING_REPORT ||
      reportType === COLLECTIONS_REPORT ||
      reportType === DAILY ||
      reportType === DRIVER_SAFETY_INSPECTION ||
      reportType === ORPHAN_INVOICE_REPORT ||
      reportType === PRE_POST_TRIP_INSPECTION ||
      reportType === RESIDENTIAL_STOP ||
      reportType === ROLL_OF_SERVICE_HISTORY ||
      reportType === UNSERVICED_RESIDENTIAL ||
      reportType === Y_DEVICE_STATUS;

    const isDataForBillingReportsDisplayed =
      reportType === AGING_REPORT ||
      reportType === BILLING_REPORT ||
      reportType === COLLECTIONS_REPORT ||
      reportType === ORPHAN_INVOICE_REPORT ||
      reportType === PAYMENT_REPORT;

    const leftAlignedReports =
      reportType === COMMUNITY_PARTICIPATION ||
      reportType === DELIVERY_UTILITY_JOB_HISTORY ||
      reportType === ROLL_OFF_JOB_HISTORY ||
      reportType === ACTIVITY_BY_ACCOUNTING_CODE ||
      reportType === MULTIPLE_DAY_WEB_PAYMENT_REPORT ||
      reportType === NEW_SERVICES_AUDIT_REPORT ||
      reportType === SINGLE_DAY_WEB_PAYMENT_REPORT;

    const isDateDisabled = reportType === ROLL_OF_SERVICE_HISTORY || reportType === Y_DEVICE_STATUS;
    const isDateRangeHidden = reportType === LOCATION_ALERTS;

    const isWasteTypeVisible = reportType === DRIVER_PRODUCTIVITY || reportType === ROUTE_PRODUCTIVITY;
    const isRouteNameMultiSelect = reportType === DRIVER_PRODUCTIVITY || reportType === ROUTE_PRODUCTIVITY;

    const isServiceTypeVisible = reportType === ROUTE_PRODUCTIVITY;

    let dateRangeMaxInterval;
    if (reportType === SINGLE_DAY_WEB_PAYMENT_REPORT) {
      dateRangeMaxInterval = { amount: DATE_RANGE_6_DAYS, unit: 'days' };
    } else if (
      reportType === EXCEPTIONS ||
      reportType === DEPOSIT_SLIP_REPORT ||
      reportType === MULTIPLE_DAY_WEB_PAYMENT_REPORT
    ) {
      dateRangeMaxInterval = { amount: DATE_RANGE_30_DAYS, unit: 'days' };
    } else if (
      reportType === CART ||
      reportType === COMMUNITY_PARTICIPATION ||
      reportType === DELIVERY_UTILITY_JOB_HISTORY ||
      reportType === DISPOSAL_TONS ||
      reportType === DRIVER_PRODUCTIVITY ||
      reportType === DRIVER_SUMMARY ||
      reportType === FAULT_CODES ||
      reportType === FUEL_LOG ||
      reportType === INSIGHT_HISTORY ||
      reportType === PRE_POST_TRIP_INSPECTION ||
      reportType === ROUTE_EXCEPTION ||
      reportType === ROLL_OFF_JOB_HISTORY ||
      reportType === ROUTE_PRODUCTIVITY ||
      reportType === ROUTE_SUMMARY ||
      reportType === SAFETY ||
      reportType === STREET_SWEEPING_ACTIVITY_SUMMARY ||
      reportType === STREET_SWEEPING_OBSTACLES ||
      reportType === ACTIVITY_BY_ACCOUNTING_CODE
    ) {
      dateRangeMaxInterval = { amount: DATE_RANGE_185_DAYS, unit: 'days' };
    } else if (reportType === NEW_SERVICES_AUDIT_REPORT) {
      dateRangeMaxInterval = { amount: DATE_RANGE_90_DAYS, unit: 'days' };
    } else if (reportType === USER_ACTIVITY) {
      dateRangeMaxInterval = { amount: DATE_RANGE_120_DAYS, unit: 'days' };
    } else if (reportType === ACTIVITY_REPORT) {
      dateRangeMaxInterval = { amount: DATE_RANGE_365_DAYS, unit: 'days' };
    } else {
      dateRangeMaxInterval = undefined;
    }

    const dataRetentionValue = getMaxSearchableMonths(dataRetention);
    const dateRangeDisabledDays = {
      before:
        reportType === USER_ACTIVITY ? GET_MONTHS_BEFORE(dataRetentionValue < 12 ? dataRetentionValue : 12) : undefined,
      after: reportType === USER_ACTIVITY ? endDateAsDate || TODAY : TODAY,
    };

    return (
      <form onSubmit={handleSubmit}>
        <PanelSectionGroup>
          <PanelSection withBorder={reportType} padding="small no">
            <Grid margin="no no xSmall no">
              <GridColumn padding="no" size="3/12">
                <Field
                  name="reportType"
                  component={DailyReportTypeDropdown}
                  placeholder={translate('insights.report')}
                  validate={[isRequired]}
                  dropdownProps={{ margin: 'no', id: 'insights-report-type' }}
                  onChange={(repType: any) => {
                    change('recipientEmail', '');
                    this.setState({ emailAddresses: [], isChecked: false, validationMessage: '' });
                    this.onReportTypeChange(isDateDisabled, repType);
                  }}
                />
              </GridColumn>
            </Grid>
          </PanelSection>
          {reportType && (
            <>
              {reportType !== LOCATION_ALERTS &&
                reportType !== CUSTOMER_EXPORT &&
                reportType !== ROLL_OF_SERVICE_HISTORY && (
                  <PanelSection vertical withBorder padding="small no ssMedium no">
                    <Text margin="xSmall no sMedium no">{translate('insights.reportConfiguration')}</Text>
                    <PanelSection padding="no">
                      {!isVehicleTypesDisabled && (
                        <GridColumn padding="no xSmall no no" size="3/12">
                          <Field
                            name="vehicleType"
                            component={VehicleTypesForVendorMultiSelect}
                            reportType={reportType}
                            multiSelectProps={{
                              placeholder: translate('vehicles.allVehicleTypes'),
                              margin: 'no',
                              formatText: getVehicleTypesText,
                              onChange: this.onVehicleTypeChange,
                            }}
                            withTechnicalName
                            vehicleRoleTypeId={ROUTE}
                            excludeVehicleTypes={excludeVehicleFilters}
                            checked={isAllCustomersChecked}
                          />
                        </GridColumn>
                      )}

                      {!isVehiclesDisabled && (
                        <GridColumn size="3/12" padding={reportType === FUEL_LOG ? 'no xSmall no no' : 'no xSmall'}>
                          <Field
                            name="vehicleName"
                            component={MultiSelect}
                            options={vehicleOptions}
                            placeholder={translate('vehicles.allVehicles')}
                            margin="no"
                            isSearchable
                            formatText={getVehiclesText}
                          />
                        </GridColumn>
                      )}

                      {!isRouteDisabled && (
                        <GridColumn size="3/12" padding={reportType === CART ? 'no xSmall no no' : 'no xSmall'}>
                          {isRouteNameMultiSelect ? (
                            <Field
                              name="routeName"
                              component={MultiSelect}
                              options={routeNameOptions}
                              placeholder={translate('routes.route')}
                              margin="no"
                              isSearchable
                              formatText={getRoutesNameText}
                            />
                          ) : (
                            <Field
                              name="routeName"
                              component={TypeAhead}
                              placeholder={translate('routes.route')}
                              getOptions={this.loadRoutesOptions}
                              margin="no"
                              isClearable
                              inputValue={isRouteDisabled ? null : undefined}
                            />
                          )}
                        </GridColumn>
                      )}
                      {reportType === DEPOSIT_SLIP_REPORT && (
                        <GridColumn size="3/12" padding="xSmall no no no">
                          <UnconnectedCheckbox
                            onChange={(e: any) => {
                              this.setState({ isWeekendOnlyChecked: e.target.checked });
                              if (e.target.checked) {
                                change('isWeekendOnly', true);
                              } else {
                                change('isWeekendOnly', false);
                              }
                            }}
                            checked={isWeekendOnlyChecked}
                            label={translate('insights.isWeekend')}
                          />
                        </GridColumn>
                      )}

                      {(reportType === SERVICE_HISTORY || isDataForBillingReportsDisplayed) && (
                        <>
                          <GridColumn size="4/12" padding="no xSmall no no">
                            <Field
                              name={reportType === SERVICE_HISTORY ? 'customerIds' : 'customerId'}
                              onChange={this.onCustomerIdsChange}
                              component={TypeAhead}
                              getOptions={this.loadCustomersOptions}
                              placeholder={
                                isAllCustomersChecked
                                  ? translate('customers.allCustomers')
                                  : translate('customers.customers')
                              }
                              isClearable={false}
                              margin="no"
                              isMulti={reportType === SERVICE_HISTORY}
                            />
                          </GridColumn>

                          {reportType !== SERVICE_HISTORY && (
                            <>
                              <GridColumn size="4/12" padding="no xSmall no no">
                                <Field
                                  name="customerLocationId"
                                  component={TypeAhead}
                                  getOptions={this.loadCustomerLocationOptions}
                                  placeholder={translate('customers.allLocations')}
                                  isClearable={true}
                                  margin="no"
                                  disabled={!customerId}
                                />
                              </GridColumn>
                            </>
                          )}

                          {reportType === SERVICE_HISTORY && (
                            <GridColumn padding="xSmall no no no" size="2/12">
                              <UnconnectedCheckbox
                                onChange={(e: any) => {
                                  this.setState({ isAllCustomersChecked: e.target.checked });
                                  if (e.target.checked) {
                                    change('customerIds', []);
                                  }
                                }}
                                checked={isAllCustomersChecked}
                                label={translate('customers.allCustomers')}
                              />
                            </GridColumn>
                          )}
                        </>
                      )}

                      {!isDriversDisabled && (
                        <GridColumn size="3/12">
                          <Field
                            fitContentWidth
                            name="driverName"
                            placeholder={translate('drivers.allDrivers')}
                            component={MultiSelect}
                            options={isDriversDisabled ? [] : driversOptions}
                            margin="no"
                            isSearchable
                            formatText={reportType === USER_ACTIVITY ? this.getDriversText : getDriversText}
                            onChange={this.onDriverNameChange}
                          />
                        </GridColumn>
                      )}
                      {isUsersVisible && (
                        <GridColumn size="3/12">
                          <Field
                            name="personId"
                            placeholder={translate('vendors.allUsers')}
                            component={MultiSelect}
                            options={usersOptions}
                            margin="no"
                            isSearchable
                            formatText={reportType === USER_ACTIVITY ? this.getUsersText : getUsersText}
                            onChange={this.onDriverNameChange}
                          />
                        </GridColumn>
                      )}
                      {isWasteTypeVisible && (
                        <GridColumn size={isServiceTypeVisible ? '3/12' : '4/12'}>
                          <Field
                            props={{ minWidth: '250px' }}
                            name="wasteMaterialType"
                            component={MultiSelect}
                            options={wasteTypesOptions}
                            placeholder={translate('common.wasteTypesTitle')}
                            margin="no"
                            isSearchable
                            formatText={getWasteTypesText}
                          />
                        </GridColumn>
                      )}
                      {isServiceTypeVisible && (
                        <GridColumn size="3/12">
                          <Field
                            props={{ minWidth: '250px' }}
                            name="serviceType"
                            component={MultiSelect}
                            options={serviceTypesOptions}
                            placeholder={translate('common.serviceTypesTitle')}
                            margin="no"
                            isSearchable
                            formatText={getServiceTypesText}
                          />
                        </GridColumn>
                      )}
                      {(!isDateDisabled || reportType === Y_DEVICE_STATUS) &&
                        (isSingleDateDisplayed ? (
                          <GridColumn
                            size="2/12"
                            padding={reportType === Y_DEVICE_STATUS ? 'no xSmall no no' : 'no xSmall'}
                          >
                            <Field
                              name="startDate"
                              component={DatePicker as any}
                              tabletAlignLeft
                              placeholder={translate('common.from')}
                              disabledDays={[
                                {
                                  before: moment().subtract(6, 'months').toDate(),
                                  after: endDateAsDate || TODAY,
                                },
                              ]}
                              margin="no"
                              validate={[isRequired, isDateValidValidator]}
                              onChange={this.onSingleDateChanged}
                            />
                          </GridColumn>
                        ) : (
                          <GridColumn size="3/12" padding={leftAlignedReports ? 'no xSmall no no' : 'no xSmall'}>
                            {!isDateRangeHidden && (
                              <Field
                                name="dateRange"
                                component={DateRangePicker as any}
                                tabletAlignLeft
                                options={
                                  reportType === NEW_SERVICES_AUDIT_REPORT
                                    ? DATE_RANGE_PICKER_30_60_90
                                    : DATE_RANGE_PICKER_TODAY_3_7_PAST
                                }
                                disabledDays={[dateRangeDisabledDays]}
                                maxInterval={dateRangeMaxInterval}
                                reportType={reportType}
                                isResetRangeVisible
                                margin="no"
                                onChange={this.onDateChanged}
                                props={{
                                  id: 'insights-report-date-range-picker',
                                  hasMarginLeft: 'smaller',
                                  getUTCDate: true,
                                }}
                              />
                            )}
                          </GridColumn>
                        ))}
                    </PanelSection>
                  </PanelSection>
                )}
              <PanelSection vertical withBorder padding="small no">
                <Text margin="xSmall no medium no">
                  {translate('insights.reportRecipients', {
                    numberOfRecipients: emailAddresses.length,
                    maxRecipients: MAX_REPORT_RECIPIENTS,
                  })}
                </Text>
                <UnconnectedCheckbox
                  checked={isChecked}
                  name="checkBox"
                  margin="no no sMedium no"
                  onChange={this.emailToMe}
                  label={translate('common.emailToMe')}
                />
                <GridColumn verticalAlign="center" padding="no">
                  <Field
                    onChange={(e: any) => {
                      change('recipientEmail', e.target.value);
                      (!e.target.value || isEmail(recipientEmail)) && this.setState({ validationMessage: '' });
                    }}
                    name="recipientEmail"
                    value={recipientEmail}
                    label={translate('insights.recipientEmail')}
                    component={Input}
                    props={{
                      margin: `${validationMessage ? 'no no xSmall' : ''}`,
                      id: 'insights-recipient-email',
                    }}
                  />

                  <Button
                    id="add-recipient-button"
                    onClick={this.addRecipientClickHandler}
                    line
                    color="primary"
                    margin="no xSmall no sMedium"
                  >
                    {translate('insights.addRecipient')}
                  </Button>
                </GridColumn>
                {validationMessage && (
                  <Text margin="no no sMedium no" color="alert">
                    {validationMessage}
                  </Text>
                )}
                <Grid multiLine padding="no">
                  {emailAddresses.map((el, index) => {
                    return (
                      <EmailRecipient key={index}>
                        {el}
                        <EmailRecipientRemove
                          onClick={e => {
                            e.preventDefault();
                            this.setState({
                              emailAddresses: emailAddresses.filter((el, i) => i !== index),
                              isChecked: el === currentAccountEmail ? false : isChecked,
                            });
                          }}
                          id={`insights-remove-recipient-${index}`}
                        >
                          <EmailRecipientRemoveIcon />
                        </EmailRecipientRemove>
                      </EmailRecipient>
                    );
                  })}
                </Grid>
              </PanelSection>

              <GridColumn margin="ssMedium no no no" size="12" align="center">
                <Button color="primary">{translate('common.export')}</Button>
              </GridColumn>
            </>
          )}
        </PanelSectionGroup>
      </form>
    );
  }
}

const mapDispatchToProps = {
  change,
  loadCustomersSimplified,
  loadServiceTypes,
  loadVehicles,
  loadWasteTypes,
  push,
};

const mapStateToProps = (state: AppState) => {
  const formSelector = formValueSelector('reports');
  const insightsDate = Cookie.get(INSIGHTS_COOKIE_DATE_KEY);
  const insightsStartDate = Cookie.get(INSIGHTS_COOKIE_START_DATE_KEY);

  const { filters } = state.common.filters;
  const vehicleFiltersPreferencesIds = getVehicleFiltersPreferencesIds(filters);
  const filtersPreferencesIds = getAllFiltersPreferencesIds(filters);

  const vehicleFiltersPreferencesName = vehicleFiltersPreferencesIds.map(
    vehicleId => VEHICLE_TYPE_IDS[vehicleId].technicalName,
  );

  return {
    accountNumber: formSelector(state, 'accountNumber'),
    currentAccountEmail: state.account.login.user.email,
    dataRetention: state.vendors.dataRetention.dataRetention,
    drivers: state.fleet.drivers.drivers,
    excludeVehicleFilters: getExcludeVehicleFilters(filters),
    filtersPreferences: state.common.filters.filters as unknown as FilterSetting[],
    filtersPreferencesIds,
    formValues: getFormValues('reports')(state) || {},
    initialValues: {
      dateRange: {
        from: insightsStartDate || insightsDate || YESTERDAY,
        to: insightsDate || TODAY_FORMATTED,
      },
      startDate: insightsDate || TODAY_FORMATTED,
      vehicleType: vehicleFiltersPreferencesName,
    },
    searchTerm: formSelector(state, 'searchTerm'),
    serviceTypes: state.common.serviceTypes.serviceTypes || [],
    users: state.vendors.users.users || [],
    vehicleFiltersPreferencesName,
    vehicles: state.fleet.vehicles.vehicles,
    vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
    wasteTypes: state.common.wasteTypes.wasteTypes || [],
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'reports',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    onSubmitFail: focusFirstInvalidField,
  })(BetaReportsForm),
);
