import { combineReducers } from 'redux';
import { reducer as runningVehicleCountsReducer } from './runningVehicleCounts';
import { reducer as insightDashboardPreferencesReducer } from './insightDashboardPreferences';
import { reducer as dailyReportTypesReducer } from './dailyReportTypes';
import { reducer as insightReportsReducer } from './insightReports';
import { reducer as reportingDetailsReducer } from './reportingDetails';
import { reducer as reportsListReducer } from './reportsList';
import { reducer as disposalSitesReducer } from './disposalSites';
import { reducer as reportingFaultCodeDetailsReducer } from './reportingFaultCodeDetails';
import { reducer as dailyDisposalSummaryReducer } from './dailyDisposalSummary';
import { reducer as reportingDataTrendsReducer } from './reportingDataTrends';
import { reducer as wasteAuditConfigurationReducer } from './wasteAuditConfiguration';
import { reducer as communityInsightsReducer } from './communityInsights';
import { reducer as mapControlReducer } from './mapControl';

export {
  loadRunningVehicleCounts,
  resetRunningVehicleCounts,
  totalVehicleCountSelector,
  vehicleTechnicalNameByIdSelector,
} from './runningVehicleCounts';
export {
  insightDashboardDaysOfOperationSelector,
  insightsDashboardPreferencesSelector,
  loadInsightDashboardPreferences,
  reorderInsightDashboardPreferences,
  resetInsightDashboardPreferences,
  saveInsightDashboardPreferences,
  toggleInsightDashboardReportType,
} from './insightDashboardPreferences';
export { loadDailyReportTypes, resetDailyReportTypes } from './dailyReportTypes';
export {
  loadFleetInsightReports,
  loadSnowPlowInsightReports,
  loadStreetSweepingInsightReports,
  loadWasteAuditInsightReports,
  resetInsightReports,
} from './insightReports';
export { loadDisposalSites, resetDisposalSites } from './disposalSites';
export {
  loadReportingDetails,
  exportReportingDetails,
  loadVehiclesByReportType,
  resetReportingDetails,
} from './reportingDetails';
export {
  loadReportingFaultCodeDetails,
  exportReportingFaultCodeDetails,
  resetReportingFaultCodeDetails,
} from './reportingFaultCodeDetails';
export {
  loadDailyDisposalSummary,
  exportDailyDisposalSummary,
  resetDailyDisposalSummary,
  totalDisposalCountSelector,
  dailyDisposalSummarySelector,
} from './dailyDisposalSummary';

export { loadReportingDataTrends, resetReportingDataTrends } from './reportingDataTrends';
export {
  loadWasteAuditConfiguration,
  resetWasteAuditConfiguration,
  wasteAuditExceptionsSelector,
} from './wasteAuditConfiguration';

export { loadReportsList, createExports } from './reportsList';
export { updateReportsEmails } from './updateReportsEmails';

export {
  clearInsight,
  exportCommunityInsights,
  loadCommunityInsights,
  resetCommunityInsights,
} from './communityInsights';

export const reducer = combineReducers({
  runningVehicleCounts: runningVehicleCountsReducer,
  insightDashboardPreferences: insightDashboardPreferencesReducer,
  dailyReportTypes: dailyReportTypesReducer,
  dailyDisposalSummary: dailyDisposalSummaryReducer,
  insightReports: insightReportsReducer,
  reportingDetails: reportingDetailsReducer,
  disposalSites: disposalSitesReducer,
  reportingFaultCodeDetails: reportingFaultCodeDetailsReducer,
  reportingDataTrends: reportingDataTrendsReducer,
  wasteAuditConfiguration: wasteAuditConfigurationReducer,
  reportsList: reportsListReducer,
  communityInsights: communityInsightsReducer,
  mapControl: mapControlReducer,
});
