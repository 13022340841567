import { AnyAction, Dispatch } from 'redux';
import { castArray, get, identity, intersection, isEmpty, size } from 'lodash-es';
import { createSelector } from 'reselect';
import { push } from 'connected-react-router';
import update from 'immutability-helper';

import { AppState } from '../../store';
import { ADMIN, ADMIN_VENDOR, SUPER_ADMIN, VENDOR, VENDOR_MANAGER } from '../constants';
import {
  changePassword as doChangePassword,
  getUser,
  login as doLogin,
  loginWithSSOToken as doLoginWithSSOToken,
  logout as doLogout,
  updateTokerSuperAdmin as doUpdateTokerSuperAdmin,
} from '../services/auth';
import { clearPermissions } from '../services/permissions';
import { completeSave, resetLanguageOptions } from '../../common/ducks';
import { createNotification } from '../../core/ducks';
import { CreateUserAccountState } from './createUserAccount';
import { DefaultVendorState } from '../../vendors/ducks/defaultVendor';
import { FullStory } from '../../fullstory';
import { getShouldRedirectToRouteware } from 'src/core/services/environment';
import { loadPermissions, resetPermissions, selfUserDestroy } from './index';
import { RUBICON_PRO_PORTAL, SMART_CITY } from '../../vendors/constants';
import { setDefaultVendor as doSetDefaultVendor } from '../../vendors/services/defaultVendor';
import { Vendor } from 'src/vendors/interfaces/Vendors';
import { VENDOR_TYPES } from '../../vendors/constants';
import translate from '../../core/services/translate';

// Actions
const START_LOGIN = 'account/login/START_LOGIN';
const COMPLETE_LOGIN = 'account/login/COMPLETE_LOGIN';
const FAIL_LOGIN = 'account/login/FAIL_LOGIN';
const FAIL_LOGIN_ACCOUNT_LOCKED = 'account/login/FAIL_LOGIN_ACCOUNT_LOCKED';
const FAIL_LOGIN_SSO_ENABLED = 'account/login/FAIL_LOGIN_SSO_ENABLED';
const COMPLETE_LOGOUT = 'account/login/COMPLETE_LOGOUT';
const RESET = 'account/login/RESET';
const START_CHANGE_PASSWORD = 'account/login/START_CHANGE_PASSWORD';
const COMPLETE_CHANGE_PASSWORD = 'account/login/COMPLETE_CHANGE_PASSWORD';
const FAIL_CHANGE_PASSWORD = 'account/login/FAIL_CHANGE_PASSWORD';

// Initial state
const user: User = getUser();

if (user) {
  FullStory.setUserVars(user);
}

export interface VendorList {
  limit: number;
  page: number;
  total: number;
  vendors: Vendor[];
}

export interface UserLoginData {
  userEmail: string;
  userPassword: string;
  userRedirectTo: string;
}

export interface User {
  displayInferenceAudit: boolean;
  email: string;
  enableGuidedTour: boolean;
  language: string;
  languageId: number;
  name: string;
  roleId: number;
  roles: string[];
  userId: string;
  vendor: Vendor;
  vendorList: VendorList;
}

export interface LoginState {
  changePasswordFailed: boolean;
  changePasswordFailedInvalidCredentials: boolean;
  changePasswordLoading: boolean;
  isAccountLocked: boolean;
  isSSoEnabled: boolean;
  isLoggedIn: boolean;
  isLoggingIn: boolean;
  isLoginFailed: boolean;
  user: User;
}

const initialState: LoginState = {
  changePasswordFailed: false,
  changePasswordFailedInvalidCredentials: false,
  changePasswordLoading: false,
  isAccountLocked: false,
  isSSoEnabled: false,
  isLoggedIn: !!user,
  isLoggingIn: false,
  isLoginFailed: false,
  user,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOGIN:
      return update(state, {
        $merge: {
          isLoginFailed: false,
          isAccountLocked: false,
          isSSoEnabled: false,
          isLoggingIn: true,
          isLoggedIn: false,
        },
      });

    case COMPLETE_LOGIN:
      return update(state, {
        $merge: {
          isLoginFailed: false,
          isAccountLocked: false,
          isSSoEnabled: false,
          isLoggingIn: false,
          isLoggedIn: true,
          user: action.user,
        },
      });

    case FAIL_LOGIN:
      return update(state, {
        $merge: {
          isLoginFailed: true,
          isAccountLocked: false,
          isSSoEnabled: false,
          isLoggingIn: false,
          isLoggedIn: false,
        },
      });

    case FAIL_LOGIN_ACCOUNT_LOCKED:
      return update(state, {
        $merge: {
          isLoginFailed: false,
          isAccountLocked: true,
          isSSoEnabled: false,
          isLoggingIn: false,
          isLoggedIn: false,
        },
      });

    case FAIL_LOGIN_SSO_ENABLED:
      return update(state, {
        $merge: {
          isLoginFailed: false,
          isAccountLocked: false,
          isSSoEnabled: true,
          isLoggingIn: false,
          isLoggedIn: false,
        },
      });

    case COMPLETE_LOGOUT:
      return update(state, {
        $merge: {
          isLoginFailed: false,
          isAccountLocked: false,
          isSSoEnabled: false,
          isLoggingIn: false,
          isLoggedIn: false,
          user: undefined,
        },
      });

    case START_CHANGE_PASSWORD:
      return update(state, {
        $merge: {
          changePasswordLoading: true,
        },
      });

    case COMPLETE_CHANGE_PASSWORD:
      return update(state, {
        $merge: {
          changePasswordLoading: false,
          changePasswordFailed: false,
        },
      });

    case FAIL_CHANGE_PASSWORD:
      return update(state, {
        $merge: {
          changePasswordLoading: false,
          changePasswordFailed: !action.invalidCredentials,
          changePasswordFailedInvalidCredentials: !!action.invalidCredentials,
        },
      });

    case RESET:
      const currentUser = getUser();

      return update(state, {
        $merge: {
          ...initialState,
          isLoggedIn: !!currentUser,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLogin = () => ({
  type: START_LOGIN,
});

export const completeLogin = (user: User) => ({
  type: COMPLETE_LOGIN,
  user,
});

const failLogin = () => ({
  type: FAIL_LOGIN,
});

const failLoginAccountLocked = () => ({
  type: FAIL_LOGIN_ACCOUNT_LOCKED,
});

const failLoginSSoEnabled = () => ({
  type: FAIL_LOGIN_SSO_ENABLED,
});

export const resetLogin = () => ({
  type: RESET,
});

export const doLoginActions =
  (user: User, redirect?: string, isLoginWithSSOToken?: boolean) => (dispatch: Dispatch) => {
    const redirectToRouteware = user?.vendor?.redirectToRouteware || false;
    const redirectData = getShouldRedirectToRouteware(redirectToRouteware, isLoginWithSSOToken);

    if (redirectData.shouldRedirect) {
      window.location.href = redirectData.newDomainURL;
    } else {
      FullStory.setUserVars(user);
      if (user.language) {
        dispatch(completeSave(user.language, user.languageId));
      }
      dispatch(completeLogin(user));
      if (!(intersection(user.roles, castArray(ADMIN)).length > 0)) {
        FullStory.setVendorVars(user.vendor);
        window.Rubicon.vendor.setVendorInfo({
          vendorId: user.vendor.id,
          vendorName: user.vendor.name,
          vendorTypeId: user.vendor.vendorTypeId,
          vendorType: VENDOR_TYPES[user.vendor.vendorTypeId].technicalName,
        });
        doSetDefaultVendor(user.vendor);
        loadPermissions()(dispatch);
      }
      if (!!user.vendor && user.vendor.vendorTypeId === RUBICON_PRO_PORTAL) {
        dispatch(push(redirect === '/invoices' ? redirect : '/workorders'));
      } else if (redirect) {
        dispatch(push(redirect));
      } else {
        // eslint-disable-next-line no-self-assign
        window.location.href = window.location.href;
      }
    }
  };

export const login =
  (email?: string, password?: string, redirect?: string, vendorId?: number) => (dispatch: Dispatch) => {
    dispatch(startLogin());
    return doLogin(email, password, vendorId)
      .then(user => {
        user?.vendor && doLoginActions(user, redirect)(dispatch);
        return user;
      })
      .catch(error => {
        const { data } = error.response;
        const errorCode = data ? data.errorDescription || data.code : null;

        switch (errorCode) {
          case 'PasswordExpired':
            createNotification(translate('account.passwordChangeRequired'), 'alert', 6000)(dispatch);
            dispatch(push('/account/change-password'));
            break;

          case 'AccountLocked':
            dispatch(failLoginAccountLocked());
            break;

          case 'SSOEnabled':
            dispatch(failLoginSSoEnabled());
            break;

          default:
          case 'IncorrectUsernamePassword':
            dispatch(failLogin());
            break;
        }
      });
  };

export const loginWithSSOToken = (token: string, redirect?: string, vendorId?: number) => (dispatch: Dispatch) => {
  dispatch(startLogin());
  return doLoginWithSSOToken(token, vendorId)
    .then(user => {
      const isLoginWithSSOToken = true;
      user?.vendor && !size(user.vendorList) && doLoginActions(user, redirect, isLoginWithSSOToken)(dispatch);
      return user;
    })
    .catch(error => {
      const { data } = error.response;
      const errorCode = data ? data.errorDescription : null;

      switch (errorCode) {
        case 'PasswordExpired':
          createNotification(translate('account.passwordChangeRequired'), 'alert', 6000)(dispatch);
          dispatch(push('/account/change-password'));
          break;

        case 'AccountLocked':
          dispatch(failLoginAccountLocked());
          break;

        default:
        case 'IncorrectUsernamePassword':
          dispatch(failLogin());
          break;
      }
    });
};

export const updateTokerSuperAdmin = (vendorId?: number) => () => {
  return doUpdateTokerSuperAdmin(vendorId).then(user => user);
};

const completeLogout = () => ({
  type: COMPLETE_LOGOUT,
});

export const logout = () => (dispatch: Dispatch) => {
  doLogout();
  clearPermissions();
  dispatch(resetPermissions());
  selfUserDestroy()(dispatch);
  dispatch(resetLanguageOptions());
  dispatch(completeLogout());
};

// Selectors
const hasRole = (loginState: LoginState, roles: string | string[]) =>
  !!loginState.user && !!intersection(loginState.user.roles, castArray(roles)).length;

const hasVendorType = (loginState: LoginState, vendorTypeId: number) =>
  !!loginState.user && get(loginState.user, 'vendor.vendorTypeId') === vendorTypeId;

const checkIfAdmin = (loginState: LoginState) => hasRole(loginState, ADMIN);
const checkIfAdminVendor = (loginState: LoginState) => hasRole(loginState, ADMIN_VENDOR);
const isSuperAdmin = (loginState: LoginState) => hasRole(loginState, SUPER_ADMIN);
const isVendor = (loginState: LoginState) => hasRole(loginState, VENDOR);
const isVendorManager = (loginState: LoginState) => hasRole(loginState, VENDOR_MANAGER);

const getVendorId = (loginState: LoginState): number | undefined => get(loginState, 'user.vendor.id');
const getUserId = (loginState: LoginState) => get(loginState, 'user.userId');
const getVendorTypeId = (loginState: LoginState) => get(loginState, 'user.vendor.vendorTypeId');
const getDeviceRoleTypeId = (loginState: LoginState) => get(loginState, 'user.vendor.deviceRoleTypeId');

export const hasRoleSelector = createSelector(hasRole, identity);
export const isAdminSelector = createSelector<LoginState, boolean, boolean>(checkIfAdmin, identity);
export const isAdmin = (state: AppState) => isAdminSelector(state.account.login);
export const isAdminVendorSelector = createSelector<LoginState, boolean, boolean>(checkIfAdminVendor, identity);
export const isAdminVendor = (state: AppState) => isAdminVendorSelector(state.account.login);
export const isSuperAdminSelector = createSelector<LoginState, boolean, boolean>(isSuperAdmin, identity);
export const isVendorSelector = createSelector(isVendor, identity);
export const isVendorManagerSelector = createSelector(isVendorManager, identity);
const isSmartCity = (loginState: LoginState, defaultVendorState: DefaultVendorState) =>
  isAdminSelector(loginState) && !isAdminVendorSelector(loginState)
    ? defaultVendorState.defaultVendor.vendorTypeId === SMART_CITY
    : hasVendorType(loginState, SMART_CITY);

export const isSmartCitySelector = createSelector(isSmartCity, identity);
export const vendorIdSelector = createSelector(getVendorId, identity);
export const userIdSelector = createSelector(getUserId, identity);
export const vendorTypeIdSelector = createSelector(getVendorTypeId, identity);
export const deviceRoleTypeIdSelector = createSelector(getDeviceRoleTypeId, identity);
const isVendorwithGusId = (loginState: LoginState, defaultVendorState: DefaultVendorState) =>
  isAdminSelector(loginState) && !isAdminVendorSelector(loginState)
    ? !isEmpty(get(defaultVendorState, 'defaultVendor.gusId'))
    : hasRole(loginState, VENDOR) && !isEmpty(get(loginState, 'user.vendor.gusId'));

export const vendorGusIdSelector = createSelector(isVendorwithGusId, identity);

export const isRubiconNonTechHauler = (
  loginState: LoginState,
  defaultVendorState: DefaultVendorState,
  createUserAccountState: CreateUserAccountState,
) => {
  if (isAdminSelector(loginState) && !isAdminVendorSelector(loginState)) {
    if (defaultVendorState.defaultVendor) return defaultVendorState.defaultVendor.vendorTypeId === RUBICON_PRO_PORTAL;
  } else if (loginState.user) {
    return hasVendorType(loginState, RUBICON_PRO_PORTAL);
  }
  return createUserAccountState.isNonTechHauler;
};

export const isNonTechHauler = (state: AppState) =>
  isRubiconNonTechHauler(state.account.login, state.vendors.defaultVendor, state.account.createUserAccount);

export const nonTechHaulerSelector = createSelector(isRubiconNonTechHauler, identity);

/**
 * Change Password Actions
 */
const startChangePassword = () => ({
  type: START_CHANGE_PASSWORD,
});

const completeChangePassword = () => ({
  type: COMPLETE_CHANGE_PASSWORD,
});

const failChangePassword = (invalidCredentials = false) => ({
  type: FAIL_CHANGE_PASSWORD,
  invalidCredentials,
});

export const changePassword = (email: string, currentPassword: string, newPassword: string) => (dispatch: Dispatch) => {
  dispatch(startChangePassword());

  doChangePassword(email, currentPassword, newPassword)
    .then(() => {
      createNotification(translate('account.passwordChangedSuccessfully'), 'success', 6000)(dispatch);
      dispatch(completeChangePassword());
      dispatch(resetLogin());
      dispatch(push('/account/login'));
    })
    .catch(error => {
      const errorCode = error && error.response && error.response.data && error.response.data.code;

      switch (errorCode) {
        case 'ChangePasswordUserNotFound':
          dispatch(failChangePassword(true));
          break;

        default:
          dispatch(failChangePassword());
          break;
      }
    });
};
