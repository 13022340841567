import { ChangeEvent } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { Address } from 'src/common/interfaces/Facility';
import { LocationPicker, TypedField } from '../../../core/components';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';

export const STREET_JOBS_ADDRESS_FORM = 'streetJobsAddressForm';

interface ComponentProps {}

interface PropsWithoutReduxForm extends ComponentProps {
  onAddressChange: (address: Address) => void;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<{ address: string }, PropsWithoutReduxForm>;

const StreetJobsAddressForm: React.FC<Props> = ({ onAddressChange }) => {
  const handleAddressChange = (_: ChangeEvent<HTMLInputElement>, address: Address) => {
    onAddressChange(address);
  };

  return (
    <form
      noValidate
      onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <TypedField
        name="address"
        component={LocationPicker}
        props={{
          placeholder: translate('common.address'),
          isClearable: true,
        }}
        onChange={handleAddressChange}
      />
    </form>
  );
};

export default reduxForm<{ address: string }, PropsWithoutReduxForm>({
  form: STREET_JOBS_ADDRESS_FORM,
  onSubmitFail: focusFirstInvalidField,
})(StreetJobsAddressForm);
