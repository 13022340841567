import FileSaver from 'file-saver';

import { EXCEL_MIME_TYPES } from '../../core/constants';
import { http } from '../../core/services/http';
import translate from '../../core/services/translate';

export const createReports = (vendorId: number, reportTypeId: number, emailAddresses: string[], configuration: any) => {
  return http.post(`reports/${vendorId}`, { reportTypeId, emailAddresses, configuration });
};

export const downloadReport = (fileDownloadUrl?: string, fileName?: string, vendorId?: number, fileId?: number) => {
  return http
    .get(fileId && vendorId ? `reports/${vendorId}/download/${fileId}` : fileDownloadUrl ? fileDownloadUrl : '', {
      responseType: 'arraybuffer',
    })
    .then(response => {
      const fileExtension = fileName?.split('.')[1];
      const blob =
        fileExtension === 'csv'
          ? new Blob([response.data], { type: 'text/csv;charset=utf-8' })
          : new Blob([response.data], {
              type: EXCEL_MIME_TYPES,
            });
      FileSaver.saveAs(
        blob,
        `${
          fileId && !fileName
            ? `${translate('vendors.driverSafetyReport.driverSafetyInspectionReport')}-${fileId}`
            : fileName
        }`,
      );
    });
};
