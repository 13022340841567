import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { withRouter, RouteComponentProps, match } from 'react-router';
import Cookie from 'js-cookie';

import { AppState } from '../../store';
import { AdminOnlyGuard, VendorWithGusIdGuard, NonTechHaulerGuard, PermissionGuard } from '../../account/components';
import {
  AUTO_DISPATCH,
  OPPORTUNITIES,
  PAYMENTS,
  SERVICE_CHANGE_REQUESTS,
  WORK_ORDERS,
  YES,
  NO,
} from '../../opportunity/constants/status';
import {
  BellNotification,
  NavigationBarContainer,
  MainNavigationBar,
  MainNavigationBarContent,
  MainNavigationLogo,
  MainNavigationItem,
  MainNavigationItemCount,
  MainNavigationIcon,
  SubNavigationBar,
  SubNavigationItem,
  SubNavigationItemCount,
  SubNavigationItemWrapper,
  NotificationsMenuItem,
  NavigationMenu,
} from './styled';
import { Button, ModalSection, Text } from '../../core/components/styled';
import {
  billingFeatureStatusSelector,
  checkIfContainerManagementIsEnabled,
  checkIfSnowPlowIsEnabled,
  checkIfStreetSweepingIsEnabled,
  isCityAlertFeatureEnabled,
  isLocationAlertFeatureEnabled,
  checkIfStreetJobsIsEnabled,
} from 'src/vendors/ducks/features';
import {
  currentUserIdSelector,
  currentVendorIdSelector,
  currentVendorTypeIdSelector,
} from '../../vendors/services/currentVendorSelector';
import { dashboardActiveCategorySelector } from '../../insights/services/dashboardActiveCategorySelector';
import { DesktopWidthView } from 'src/core/components/mediaQueries/DesktopWidthView';
import { DuckFunction } from '../../contracts/ducks';
import {
  flagLocationStatusSelector,
  geoFenceSelector,
  loadFeatures,
  wasteAuditStatusSelector,
} from '../../vendors/ducks';
import { getTokenFromUrl } from '../utils/navigation';
import { INSIGHTS_EXPORT } from '../../account/constants';
import { isOldDashboardNavigationLayout } from '../services/persistentDashboardNavigationLayout';
import { isRubiconNonTechHauler, vendorGusIdSelector } from '../../account/ducks';
import { loadDispatchOpportunitiesCount } from '../ducks';
import { MobileNavigationBar } from './MobileNavigationBar';
import { MobileWidthView } from 'src/core/components/mediaQueries/MobileWidthView';
import { Modal } from '../../core/components';
import { NavigationBarSettingsGear } from './NavigationBarSettingsGear';
import { SelectDefaultVendorModalToggle } from '../../vendors/components/modals';
import { SELECTED_VENDOR_ID_COOKIE } from 'src/common/constants';
import { SESSION_COOKIE_KEY } from 'src/account/services/session';
import { showNotificationIfNewReleaseNotes } from '../../settings/ducks/releaseNotes';
import { SMART_CITY } from '../../vendors/constants';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import history from 'src/core/services/history';
import matchRoute from '../../utils/services/matchRoute';
import translate from '../../core/services/translate';
import {
  hasRouteTrackerVehiclesSelector,
  loadVehicleTypesForVendor,
  hasSnowPlowVehiclesEnabledSelector,
  hasStreetSweeperVehiclesEnabledSelector,
} from 'src/fleet/ducks/vehicleTypesForVendor';

interface MatchParams {
  token?: string;
}

const isRoutesMainNavigationItemActive = (_match: match<MatchParams>, { pathname }: Location) =>
  matchRoute(pathname, '/routes') || matchRoute(pathname, '/route-import');

const isInsightsMainNavigationItemActive = (_match: match<MatchParams>, { pathname }: Location) =>
  matchRoute(pathname, '/insights');

const isCustomersMainNavigationItemActive = (_match: match<MatchParams>, { pathname }: Location) =>
  matchRoute(pathname, '/customers');

const isFinanceMainNavigationItemActive = (_match: match<MatchParams>, { pathname }: Location) =>
  matchRoute(pathname, '/finance');

const isFleetMainNavigationItemActive = (_match: match<MatchParams>, { pathname }: Location) =>
  matchRoute(pathname, '/fleet/vehicles') ||
  matchRoute(pathname, '/fleet/resources') ||
  matchRoute(pathname, '/fleet/containers') ||
  matchRoute(pathname, '/fleet/facilities') ||
  matchRoute(pathname, '/fleet/geo-fences') ||
  matchRoute(pathname, '/fleet/alerts');

const isPaymentMainNavigationItemActive = (_match: match<MatchParams>, { pathname }: Location) =>
  matchRoute(pathname, '/payments');

const isRubiconSelectMainNavigationItemActive = (_match: match<MatchParams>, { pathname }: Location) =>
  matchRoute(pathname, '/rubicon-select');

const routeTrackerNavItem = 'routes.tracker';
const routePlannerNavItem = 'routes.planner.routePlanner';
const snowTrackerNavItem = 'routes.snowTracker';
const sweeperTrackerNavItem = 'routes.sweeperTracker';

interface Props extends RouteComponentProps<MatchParams> {
  currentUserId: string;
  currentVendorId: number;
  currentVendorTypeId: number;
  features: any;
  hasRouteTrackerVehicles: boolean;
  hasSnowPlowVehiclesEnabled: boolean;
  hasStreetSweeperVehiclesEnabled: boolean;
  isBillingFeatureActive: boolean;
  isCityAlertEnabled: boolean;
  isContainerManagementEnabled: boolean;
  isFlagLocationActive: boolean;
  isGeoFenceActive: boolean;
  isLocationAlertEnabled: boolean;
  isNonTechHauler: boolean;
  isSnowPlowFeatureEnabled: boolean;
  isStreetJobsFeatureEnabled: boolean;
  isStreetSweepingFeatureEnabled: boolean;
  isVendorWithGusId?: boolean;
  isWasteAuditActive: boolean;
  loadDispatchOpportunitiesCount: DuckFunction<typeof loadDispatchOpportunitiesCount>;
  loadFeatures: (vendorId: number) => Promise<any>;
  loadVehicleTypesForVendor: DuckFunction<typeof loadVehicleTypesForVendor>;
  notificationCounts: any;
  selectedVendorId?: string;
  selfUser: any;
  sessionCookie?: string;
  showNotificationIfNewReleaseNotes: DuckFunction<typeof showNotificationIfNewReleaseNotes>;
}
interface State {
  isNotificationsMenuOpen: boolean;
  isSessionChanged: boolean;
  isVendorChanged: boolean;
}

let vendorOrSessionCookieInterval: any;

class NavigationBar extends PureComponent<Props, State> {
  state = {
    isNotificationsMenuOpen: false,
    isSessionChanged: false,
    isVendorChanged: false,
  };

  static defaultProps = {
    features: {},
    notificationCounts: {},
  };

  get isLoggedIn() {
    return (this.props.selfUser && this.props.selfUser.vendor.vendorTypeId) || this.props.currentUserId;
  }

  componentDidMount() {
    const {
      currentUserId,
      currentVendorId,
      features,
      isVendorWithGusId,
      loadDispatchOpportunitiesCount,
      loadFeatures,
      loadVehicleTypesForVendor,
      match: {
        params: { token },
      },
      selectedVendorId,
      sessionCookie,
      showNotificationIfNewReleaseNotes,
    } = this.props;

    if (!!currentUserId && !!currentVendorId && isVendorWithGusId) {
      loadDispatchOpportunitiesCount(currentUserId, currentVendorId, token);
    }
    if (!!currentVendorId && !features.features.length && !features.isLoading) {
      loadFeatures(currentVendorId);
      loadVehicleTypesForVendor(currentVendorId);
    }

    if (this.isLoggedIn) {
      showNotificationIfNewReleaseNotes();
    }

    vendorOrSessionCookieInterval = setInterval(() => {
      if (Cookie.get(SELECTED_VENDOR_ID_COOKIE) !== selectedVendorId) {
        this.setState({ isVendorChanged: true });
      } else {
        this.setState({ isVendorChanged: false });
      }

      if (Cookie.get(SESSION_COOKIE_KEY) !== sessionCookie && !Cookie.get(SESSION_COOKIE_KEY)) {
        this.setState({ isSessionChanged: true, isVendorChanged: false });
      } else {
        this.setState({ isSessionChanged: false });
      }
    }, 1000);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closeNotificationsMenu);
  }

  getSubMenuIconCount = (key: string) => {
    const { notificationCounts } = this.props;
    if (key === OPPORTUNITIES) return notificationCounts.opportunities + notificationCounts.bidOpportunities;
    else if (key === SERVICE_CHANGE_REQUESTS) return notificationCounts.serviceChangeRequests;
    else if (key === WORK_ORDERS) return notificationCounts.workOrders;
  };

  getMainNavigationItem = (urlPath: string, isActive: any, iconName: string, titleTranslationKey: string) => (
    <React.Fragment>
      <MainNavigationItem to={urlPath} isActive={isActive}>
        <MainNavigationIcon icon={iconName} />
        {translate(titleTranslationKey)}
      </MainNavigationItem>
    </React.Fragment>
  );

  getSubNavigationItem = (url: string, titleTranslationKey: string, notificationCountKey?: boolean | string) => {
    const { pathname } = this.props.location;
    const dailyRoutesNavIsActive =
      matchRoute(pathname, '/route-tracker') && titleTranslationKey === routeTrackerNavItem;
    const routePlannerNavIsActive =
      matchRoute(pathname, '/route-planner') && titleTranslationKey === routePlannerNavItem;
    const snowTrackerNavIsActive = matchRoute(pathname, '/snow-tracker') && titleTranslationKey === snowTrackerNavItem;
    const sweeperTrackerNavIsActive =
      matchRoute(pathname, '/sweeper-tracker') && titleTranslationKey === sweeperTrackerNavItem;

    let notificationCount = 0;
    if (notificationCountKey) {
      notificationCount = this.getSubMenuIconCount(notificationCountKey as string);
    }
    const urlPath = `${url}?showActionRequired=${!!notificationCount ? YES : NO}`;

    return (
      <React.Fragment>
        <SubNavigationItemWrapper
          isActionRequired={notificationCountKey ? true : false}
          active={
            dailyRoutesNavIsActive || routePlannerNavIsActive || snowTrackerNavIsActive || sweeperTrackerNavIsActive
          }
        >
          <SubNavigationItem to={url}>{translate(titleTranslationKey)}</SubNavigationItem>
        </SubNavigationItemWrapper>
        {notificationCountKey && (
          <SubNavigationItem to={urlPath}>
            <SubNavigationItemCount isVisible={!!notificationCount}>
              {notificationCount > 99 ? '99+' : notificationCount}
            </SubNavigationItemCount>
          </SubNavigationItem>
        )}
      </React.Fragment>
    );
  };

  onDefaultVendorSelected = () => {
    clearInterval(vendorOrSessionCookieInterval);
  };

  getVendorPortalChanger = () => (
    <AdminOnlyGuard>
      <SelectDefaultVendorModalToggle onDefaultVendorSelected={this.onDefaultVendorSelected} />
    </AdminOnlyGuard>
  );

  getSettingsPanel = (signInNonTechHaulerUrl: (url: string) => string) => (
    <NavigationBarSettingsGear
      isLoggedIn={(this.props.selfUser && this.props.selfUser.vendor.vendorTypeId) || this.props.currentUserId}
      signInNonTechHaulerUrl={signInNonTechHaulerUrl('/account/login')}
    />
  );

  getNotificationSubNavigationUrl = (url: string, showActionRequired: string | number) => {
    const {
      location: { pathname },
    } = this.props;
    const autoDispatchToken = matchRoute(pathname, '/workorders')
      ? getTokenFromUrl(pathname, WORK_ORDERS.toLowerCase())
      : getTokenFromUrl(pathname, AUTO_DISPATCH);

    const opportunitiesToken = getTokenFromUrl(pathname, OPPORTUNITIES.toLowerCase());

    const showOpportunitiesForToken = matchRoute(pathname, 'hauler-opportunities/') && opportunitiesToken;

    const showOpenDispatchesForToken = matchRoute(pathname, 'workorders/') && autoDispatchToken;

    if (showOpportunitiesForToken) return url + opportunitiesToken + `?showActionRequired=${showActionRequired}`;
    else if (showOpenDispatchesForToken) return url + autoDispatchToken + `?showActionRequired=${showActionRequired}`;
    return url + `?showActionRequired=${showActionRequired}`;
  };

  getMainNavigationRubiconLogo = (path: string) => {
    const {
      location: { pathname },
    } = this.props;

    const autoDispatchToken = matchRoute(pathname, '/workorders')
      ? getTokenFromUrl(pathname, WORK_ORDERS.toLowerCase())
      : getTokenFromUrl(pathname, AUTO_DISPATCH);
    const opportunitiesToken = getTokenFromUrl(pathname, OPPORTUNITIES.toLowerCase());
    const paymentsToken = getTokenFromUrl(pathname, PAYMENTS);

    const opportunitiesPageActiveUrl =
      !!opportunitiesToken || !!autoDispatchToken || !!paymentsToken
        ? '/opportunities/hauler-opportunities/'
        : '/opportunities/';

    const { notificationCounts } = this.props;
    const showActionRequired = notificationCounts.total ? YES : NO;
    const opportunitiesCount = notificationCounts.opportunities + notificationCounts.bidOpportunities;

    return (
      <React.Fragment>
        <MainNavigationLogo to={path} />
        {!!notificationCounts.total && (
          <BellNotification onClick={this.openNotificationsMenu}>
            <MainNavigationItemCount isVisible={notificationCounts.total} onClick={this.openNotificationsMenu}>
              {notificationCounts.total > 99 ? '99+' : notificationCounts.total}
            </MainNavigationItemCount>
            <NavigationMenu isOpen={this.state.isNotificationsMenuOpen} isNotifications={notificationCounts.total}>
              <div>
                {!!opportunitiesCount && (
                  <NotificationsMenuItem
                    to={this.getNotificationSubNavigationUrl(opportunitiesPageActiveUrl, showActionRequired)}
                    id="menu-application-button"
                  >
                    <SubNavigationItemCount isVisible={opportunitiesCount}>
                      {opportunitiesCount > 99 ? '99+' : opportunitiesCount}
                    </SubNavigationItemCount>

                    <Text block size="small" margin="xxSmall">
                      {translate('opportunity.opportunities.opportunities')}
                    </Text>
                  </NotificationsMenuItem>
                )}
                {!!notificationCounts.serviceChangeRequests && (
                  <NotificationsMenuItem
                    to={this.getNotificationSubNavigationUrl(
                      '/opportunities/service-change-requests/',
                      showActionRequired,
                    )}
                    id="menu-application-button"
                  >
                    <SubNavigationItemCount isVisible={notificationCounts.serviceChangeRequests}>
                      {notificationCounts.serviceChangeRequests > 99 ? '99+' : notificationCounts.serviceChangeRequests}
                    </SubNavigationItemCount>
                    <Text block size="small" margin="xxSmall">
                      {translate('opportunity.serviceChangeRequests')}
                    </Text>
                  </NotificationsMenuItem>
                )}
                {!!notificationCounts.workOrders && (
                  <NotificationsMenuItem
                    to={this.getNotificationSubNavigationUrl('/workorders/', showActionRequired)}
                    id="menu-application-button"
                  >
                    <SubNavigationItemCount isVisible={notificationCounts.workOrders}>
                      {notificationCounts.workOrders > 99 ? '99+' : notificationCounts.workOrders}
                    </SubNavigationItemCount>

                    <Text block size="small" margin="xxSmall no no no">
                      {translate('autoDispatch.workOrders')}
                    </Text>
                  </NotificationsMenuItem>
                )}
              </div>
            </NavigationMenu>
          </BellNotification>
        )}
      </React.Fragment>
    );
  };

  closeNotificationsMenu = () => {
    this.setState({ isNotificationsMenuOpen: false });
    document.removeEventListener('click', this.closeNotificationsMenu);
  };

  openNotificationsMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    this.setState({ isNotificationsMenuOpen: !this.state.isNotificationsMenuOpen });
    document.addEventListener('click', this.closeNotificationsMenu);
  };

  render() {
    const {
      currentVendorTypeId,
      hasRouteTrackerVehicles,
      hasSnowPlowVehiclesEnabled,
      hasStreetSweeperVehiclesEnabled,
      isBillingFeatureActive,
      isCityAlertEnabled,
      isContainerManagementEnabled,
      isFlagLocationActive,
      isGeoFenceActive,
      isLocationAlertEnabled,
      isNonTechHauler,
      isSnowPlowFeatureEnabled,
      isStreetJobsFeatureEnabled,
      isStreetSweepingFeatureEnabled,
      isWasteAuditActive,
      location: { pathname },
    } = this.props;
    const { isSessionChanged, isVendorChanged } = this.state;

    const showRoutesSubNavigationBar = matchRoute(pathname, '/routes') || matchRoute(pathname, '/route-import');

    const showDashboardSubNavigationBar =
      matchRoute(pathname, '/dashboard') ||
      matchRoute(pathname, '/workorders') ||
      matchRoute(pathname, '/opportunities/hauler-opportunities') ||
      matchRoute(pathname, '/opportunities/') ||
      matchRoute(pathname, '/opportunities/hauler-deliveries') ||
      matchRoute(pathname, '/fleet/autodispatch/serviceconfirmation') ||
      matchRoute(pathname, '/fleet/autodispatch/rubicondispatches') ||
      matchRoute(pathname, '/opportunities/service-change-requests') ||
      matchRoute(pathname, '/rubicon-services');

    const showFleetSubNavigationBar =
      matchRoute(pathname, '/fleet/vehicles') ||
      matchRoute(pathname, '/fleet/resources') ||
      matchRoute(pathname, '/fleet/containers') ||
      matchRoute(pathname, '/fleet/facilities') ||
      matchRoute(pathname, '/fleet/geo-fences') ||
      matchRoute(pathname, '/fleet/alerts');

    const showHaulerProfileSubNavigationBar =
      matchRoute(pathname, '/hauler-profile/overview') ||
      matchRoute(pathname, '/hauler-profile/compliance') ||
      matchRoute(pathname, '/hauler-profile/equipments') ||
      matchRoute(pathname, '/hauler-profile/services') ||
      matchRoute(pathname, '/hauler-profile/contacts') ||
      matchRoute(pathname, '/hauler-profile/agreements');

    const autoDispatchToken = matchRoute(pathname, '/workorders')
      ? getTokenFromUrl(pathname, WORK_ORDERS.toLowerCase())
      : getTokenFromUrl(pathname, AUTO_DISPATCH);
    const opportunitiesToken = getTokenFromUrl(pathname, OPPORTUNITIES.toLowerCase());
    const paymentsToken = getTokenFromUrl(pathname, PAYMENTS);

    const showOpenDispatchesForToken =
      (matchRoute(pathname, 'opendispatches/') ||
        matchRoute(pathname, 'rubicondispatches/') ||
        matchRoute(pathname, 'workorders/') ||
        matchRoute(pathname, 'serviceconfirmation/')) &&
      autoDispatchToken;

    const showOpportunitiesForToken =
      (matchRoute(pathname, 'hauler-opportunities/') || matchRoute(pathname, 'invoices/')) && opportunitiesToken;

    const showPaymentsForToken =
      (matchRoute(pathname, 'recurring-service/') ||
        matchRoute(pathname, 'oncall-service/') ||
        matchRoute(pathname, 'processed-service/')) &&
      paymentsToken;

    const opportunitiesUrl = (urlPrefix: string) => {
      if (showOpportunitiesForToken) return urlPrefix + opportunitiesToken;
      else if (showOpenDispatchesForToken) return urlPrefix + autoDispatchToken;
      return urlPrefix;
    };
    const openDispatchesUrl = (urlPrefix: string) => {
      if (showOpenDispatchesForToken) return urlPrefix + autoDispatchToken;
      else if (showOpportunitiesForToken) return urlPrefix + opportunitiesToken;
      return urlPrefix;
    };

    const paymentsUrl = (urlPrefix: string) => (showPaymentsForToken ? urlPrefix + paymentsToken : urlPrefix);
    const signInNonTechHaulerUrl = (urlPrefix: string) => {
      if (showOpenDispatchesForToken) return `${urlPrefix}?autoDispatchToken=${autoDispatchToken}`;
      else if (showOpportunitiesForToken) return `${urlPrefix}?opportunitiesToken=${opportunitiesToken}`;
      return urlPrefix;
    };

    const displayCommunityPage = isFlagLocationActive && currentVendorTypeId === SMART_CITY;

    const displayInvoicePage = currentVendorTypeId !== SMART_CITY;

    const oldDashboardNavigationLayout = isOldDashboardNavigationLayout();

    const workordersPageActiveUrl = matchRoute(pathname, '/fleet/autodispatch/rubicondispatches')
      ? '/fleet/autodispatch/rubicondispatches/'
      : '/workorders/';

    const clearVendorOrSessionCookieInterval = (action: any) => {
      clearInterval(vendorOrSessionCookieInterval);
      this.setState({ isVendorChanged: false, isSessionChanged: false });

      setTimeout(() => {
        if (typeof action === 'function') action();
      });
    };

    const refreshPage = () => {
      let action: any = window.location.reload();

      // start of section: custom redirect when changing vendor in different tab
      const locationHref = window.location.href;

      const removeTrailingNumber = (url: string) => {
        return url.replace(/\/\d+$/, '');
      };

      const checkIfEndsWithNumber = (url: string) => {
        return /\/\d+$/.test(url);
      };

      const removeEditStringWithTrailingNumber = (url: string) => {
        return url.replace(/\/\d+\/edit$/, '');
      };

      const checkIfEndsWithNumberAndEditString = (url: string) => {
        return /\d+\/edit$/.test(url);
      };

      if (checkIfEndsWithNumber(locationHref) || locationHref.includes('routes/dispatch-board')) {
        const locationPathName = removeTrailingNumber(`${window.location.origin}${window.location.pathname}`);
        action = () => (window.location.href = locationPathName);
      }

      if (checkIfEndsWithNumberAndEditString(locationHref)) {
        const locationPathName = removeEditStringWithTrailingNumber(
          `${window.location.origin}${window.location.pathname}`,
        );
        action = () => (window.location.href = locationPathName);
      }

      if (locationHref.includes('routes/snow-tracker')) {
        const locationPathName = `/routes/snow-tracker?vehicleTypeIds=${SNOW_PLOW_ID}`;
        action = () => (window.location.href = locationPathName);
      }

      if (locationHref.includes('routes/sweeper-tracker')) {
        const locationPathName = `/routes/sweeper-tracker?vehicleTypeIds=${STREET_SWEEPER_ID}`;
        action = () => (window.location.href = locationPathName);
      }

      if (
        locationHref.includes('routes/route-templates/snow-plow') ||
        locationHref.includes('routes/route-templates/street-sweeper')
      ) {
        const locationPathName = `/routes/route-templates`;
        action = () => (window.location.href = locationPathName);
      }
      // end of section: custom redirect when changing vendor in different tab

      clearVendorOrSessionCookieInterval(action);
    };

    const redirectToLogin = () => {
      clearVendorOrSessionCookieInterval(
        history.push({ pathname: '/account/logout', state: { from: history.location } }),
      );
    };

    const opportunitiesPageActiveUrl =
      !!opportunitiesToken || !!autoDispatchToken || !!paymentsToken
        ? '/opportunities/hauler-opportunities/'
        : '/opportunities/';

    const isSnowPlowConfigured = isSnowPlowFeatureEnabled && hasSnowPlowVehiclesEnabled;

    const isStreetSweeperConfigured = isStreetSweepingFeatureEnabled && hasStreetSweeperVehiclesEnabled;

    const isSnowSweeperOnly = (isSnowPlowConfigured || isStreetSweeperConfigured) && !hasRouteTrackerVehicles;

    return (
      <>
        <DesktopWidthView isNavigation>
          <NavigationBarContainer>
            {!showOpportunitiesForToken && !showOpenDispatchesForToken && !showPaymentsForToken && !isNonTechHauler && (
              <MainNavigationBar>
                <MainNavigationBarContent>
                  {this.getMainNavigationRubiconLogo('/dashboard')}
                  {this.getMainNavigationItem(
                    isSnowSweeperOnly
                      ? isSnowPlowConfigured
                        ? `/routes/snow-tracker?vehicleTypeIds=${SNOW_PLOW_ID}`
                        : `/routes/sweeper-tracker?vehicleTypeIds=${STREET_SWEEPER_ID}`
                      : '/routes/route-tracker',
                    isRoutesMainNavigationItemActive,
                    'routesCircle',
                    'routes.dispatch',
                  )}

                  {this.getMainNavigationItem(
                    '/customers/customers',
                    isCustomersMainNavigationItemActive,
                    'customer',
                    'customers.customers',
                  )}

                  {this.getMainNavigationItem('/fleet', isFleetMainNavigationItemActive, 'fleetCircle', 'common.fleet')}

                  {isBillingFeatureActive &&
                    this.getMainNavigationItem(
                      '/finance/billing',
                      isFinanceMainNavigationItemActive,
                      'financeCircle',
                      'finance.finance',
                    )}

                  {this.getMainNavigationItem(
                    '/insights/insightsfleet',
                    isInsightsMainNavigationItemActive,
                    'insightsCircle',
                    'insights.insights',
                  )}

                  {this.getMainNavigationItem(
                    '/rubicon-select',
                    isRubiconSelectMainNavigationItemActive,
                    'rubiconSelect',
                    'tooltips.select',
                  )}

                  {this.getVendorPortalChanger()}
                  {this.getSettingsPanel(signInNonTechHaulerUrl)}
                </MainNavigationBarContent>
              </MainNavigationBar>
            )}

            {(showOpportunitiesForToken || showOpenDispatchesForToken || isNonTechHauler) && (
              <MainNavigationBar>
                <MainNavigationBarContent>
                  {this.getMainNavigationRubiconLogo('/workorders')}
                  {this.getMainNavigationItem(
                    '/rubicon-select',
                    isRubiconSelectMainNavigationItemActive,
                    'rubiconSelect',
                    'tooltips.select',
                  )}
                  {this.getVendorPortalChanger()}
                  {this.getSettingsPanel(signInNonTechHaulerUrl)}
                </MainNavigationBarContent>
              </MainNavigationBar>
            )}

            {showPaymentsForToken && (
              <MainNavigationBar>
                <MainNavigationBarContent>
                  {this.getMainNavigationRubiconLogo('/payments/recurring-service/')}
                  {this.getMainNavigationItem(
                    paymentsUrl('/payments/recurring-service/'),
                    isPaymentMainNavigationItemActive,
                    'paymentCircle',
                    'payment.payments',
                  )}
                </MainNavigationBarContent>
              </MainNavigationBar>
            )}

            {showFleetSubNavigationBar && (
              <SubNavigationBar>
                {this.getSubNavigationItem('/fleet/vehicles', 'vehicles.vehicles')}
                {this.getSubNavigationItem('/fleet/resources', 'resources.resources')}
                {isContainerManagementEnabled &&
                  this.getSubNavigationItem('/fleet/containers', 'containers.containers')}
                {this.getSubNavigationItem('/fleet/facilities', 'facilities.facilities')}
                {isGeoFenceActive && this.getSubNavigationItem('/fleet/geo-fences', 'routes.geoFences.geoFencesTitle')}
                {(isCityAlertEnabled || isLocationAlertEnabled) &&
                  this.getSubNavigationItem(`/fleet/alerts`, 'vendors.cityAlerts.alerts')}
              </SubNavigationBar>
            )}

            {showRoutesSubNavigationBar && !isNonTechHauler && (
              <SubNavigationBar>
                {!isSnowSweeperOnly && this.getSubNavigationItem('/routes/route-tracker', routeTrackerNavItem)}
                {isSnowPlowConfigured &&
                  this.getSubNavigationItem(`/routes/snow-tracker?vehicleTypeIds=${SNOW_PLOW_ID}`, snowTrackerNavItem)}
                {isStreetSweeperConfigured &&
                  this.getSubNavigationItem(
                    `/routes/sweeper-tracker?vehicleTypeIds=${STREET_SWEEPER_ID}`,
                    sweeperTrackerNavItem,
                  )}
                {this.getSubNavigationItem('/routes/route-templates', routePlannerNavItem)}
                {this.getSubNavigationItem('/routes/dispatch-board', 'routes.dispatchBoard')}
              </SubNavigationBar>
            )}

            {showDashboardSubNavigationBar && !oldDashboardNavigationLayout && isNonTechHauler && (
              <SubNavigationBar>
                {this.getSubNavigationItem(
                  opportunitiesUrl(opportunitiesPageActiveUrl),
                  'opportunity.opportunities.opportunities',
                  OPPORTUNITIES,
                )}
                {this.getSubNavigationItem('/rubicon-services', 'customers.rubiconServices', 'Rubicon Services')}
                {this.getSubNavigationItem(
                  '/opportunities/service-change-requests/',
                  'opportunity.serviceChangeRequests',
                )}

                <NonTechHaulerGuard>
                  {this.getSubNavigationItem(
                    openDispatchesUrl(workordersPageActiveUrl),
                    'autoDispatch.workOrders',
                    WORK_ORDERS,
                  )}
                </NonTechHaulerGuard>
                {this.getSubNavigationItem('/invoices', 'invoice.invoices')}
              </SubNavigationBar>
            )}

            {showDashboardSubNavigationBar && !oldDashboardNavigationLayout && !isNonTechHauler && (
              <VendorWithGusIdGuard>
                <SubNavigationBar>
                  {this.getSubNavigationItem(
                    opportunitiesUrl(opportunitiesPageActiveUrl),
                    'opportunity.opportunities.opportunities',
                    OPPORTUNITIES,
                  )}
                  {this.getSubNavigationItem('/rubicon-services', 'customers.rubiconServices')}
                  {this.getSubNavigationItem(
                    '/opportunities/service-change-requests/',
                    'opportunity.serviceChangeRequests',
                  )}
                  {this.getSubNavigationItem(
                    openDispatchesUrl(workordersPageActiveUrl),
                    'autoDispatch.workOrders',
                    WORK_ORDERS,
                  )}
                  {displayInvoicePage && this.getSubNavigationItem('/invoices', 'invoice.invoices')}
                </SubNavigationBar>
              </VendorWithGusIdGuard>
            )}

            {matchRoute(pathname, '/customers') && (
              <SubNavigationBar>
                {this.getSubNavigationItem('/customers/customers', 'customers.customers')}

                {isStreetJobsFeatureEnabled &&
                  (isSnowPlowFeatureEnabled || isStreetSweepingFeatureEnabled) &&
                  this.getSubNavigationItem('/customers/streets', 'customers.streets.streets')}

                {this.getSubNavigationItem('/customers/suspended-locations', 'customers.suspendedLocations')}
                {this.getSubNavigationItem('/customers/service-network', 'customers.streetNetwork.serviceNetwork')}
              </SubNavigationBar>
            )}

            {matchRoute(pathname, '/insights/') && (
              <SubNavigationBar>
                {this.getSubNavigationItem('/insights/insightsfleet', 'common.fleet')}
                {isWasteAuditActive && this.getSubNavigationItem('/insights/wasteaudit', 'dashboard.wasteAudit')}
                {displayCommunityPage &&
                  this.getSubNavigationItem('/insights/community-insights', 'insights.community')}
                <PermissionGuard permission={INSIGHTS_EXPORT}>
                  {this.getSubNavigationItem('/insights/reports', 'insights.dataExport')}
                </PermissionGuard>
              </SubNavigationBar>
            )}

            {matchRoute(pathname, '/finance') && (
              <SubNavigationBar>
                {this.getSubNavigationItem('/finance/billing', 'finance.billing')}
                {this.getSubNavigationItem('/finance/payments', 'finance.applyChecks.applyChecks')}
                {/* {this.getSubNavigationItem('/finance/rate-configuration', 'finance.rateManager.rateManager')} */}
                {/* {this.getSubNavigationItem(
                  '/finance/payment-management',
                  'finance.paymentManagement.paymentManagement',
                )} */}
              </SubNavigationBar>
            )}

            {matchRoute(pathname, '/invoices') && (
              <SubNavigationBar>
                {this.getSubNavigationItem(
                  opportunitiesUrl(opportunitiesPageActiveUrl),
                  'opportunity.opportunities.opportunities',
                  OPPORTUNITIES,
                )}
                {this.getSubNavigationItem(
                  '/opportunities/service-change-requests/',
                  'opportunity.serviceChangeRequests',
                )}
                {this.getSubNavigationItem(
                  openDispatchesUrl(workordersPageActiveUrl),
                  'autoDispatch.workOrders',
                  'RubiconDispatches',
                )}

                {displayInvoicePage && this.getSubNavigationItem('/invoices', 'invoice.invoices')}
              </SubNavigationBar>
            )}

            {showHaulerProfileSubNavigationBar && (
              <SubNavigationBar>
                {this.getSubNavigationItem('/hauler-profile/overview', 'haulerProfile.overview')}
                {this.getSubNavigationItem('/hauler-profile/compliance', 'haulerProfile.compliance')}
                {this.getSubNavigationItem('/hauler-profile/equipments', 'haulerProfile.equipments.title')}
                {this.getSubNavigationItem('/hauler-profile/services', 'haulerProfile.services')}
                {this.getSubNavigationItem('/hauler-profile/contacts', 'haulerProfile.contacts')}
                {this.getSubNavigationItem('/hauler-profile/agreements', 'haulerProfile.agreements')}
              </SubNavigationBar>
            )}
          </NavigationBarContainer>
        </DesktopWidthView>
        <MobileWidthView isNavigation>
          <MobileNavigationBar isLoggedIn={this.isLoggedIn} />
        </MobileWidthView>

        {isVendorChanged && (
          <Modal size="small">
            <ModalSection align="center">
              <Text block size="large">
                {translate('settings.vendorChangedMessage')}
              </Text>
              <Button color="primary" onClick={refreshPage} margin="medium no no no">
                {translate('settings.refreshPage')}
              </Button>
            </ModalSection>
          </Modal>
        )}

        {isSessionChanged && (
          <Modal size="small">
            <ModalSection align="center">
              <Text block size="large">
                {translate('settings.sessionChangedMessage')}
              </Text>
              <Button color="primary" onClick={redirectToLogin} margin="medium no no no">
                {translate('account.login')}
              </Button>
            </ModalSection>
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  activeInsightsCategories: dashboardActiveCategorySelector(state.insights.insightDashboardPreferences),
  currentUserId: currentUserIdSelector(state.account.login) as any,
  currentVendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
  currentVendorTypeId: (currentVendorTypeIdSelector as any)(state.account.login, state.vendors.defaultVendor),
  features: state.vendors.features,
  isContainerManagementEnabled: checkIfContainerManagementIsEnabled(state),
  isFlagLocationActive: flagLocationStatusSelector(state.vendors.features.features) as any as boolean,
  isGeoFenceActive: geoFenceSelector(state.vendors.features.features) as any as boolean,
  isSnowPlowFeatureEnabled: checkIfSnowPlowIsEnabled(state),
  isStreetSweepingFeatureEnabled: checkIfStreetSweepingIsEnabled(state),
  isStreetJobsFeatureEnabled: checkIfStreetJobsIsEnabled(state),
  isNonTechHauler: isRubiconNonTechHauler(
    state.account.login,
    state.vendors.defaultVendor,
    state.account.createUserAccount,
  ),
  isVendorWithGusId: vendorGusIdSelector(state.account.login, state.vendors.defaultVendor),
  isWasteAuditActive: wasteAuditStatusSelector(state.vendors.features.features) as any as boolean,
  location: state.router.location,
  notificationCounts: state.common.dispatchOpportunitiesCount.dispatchOpportunitiesCounts as any[],
  selectedVendorId: Cookie.get(SELECTED_VENDOR_ID_COOKIE),
  sessionCookie: Cookie.get(SESSION_COOKIE_KEY),
  selfUser: state.account.createUserAccount.selfUser,
  hasRouteTrackerVehicles: hasRouteTrackerVehiclesSelector(state),
  hasSnowPlowVehiclesEnabled: hasSnowPlowVehiclesEnabledSelector(state),
  hasStreetSweeperVehiclesEnabled: hasStreetSweeperVehiclesEnabledSelector(state),
  isCityAlertEnabled: isCityAlertFeatureEnabled(state),
  isLocationAlertEnabled: isLocationAlertFeatureEnabled(state),
  isBillingFeatureActive: !!billingFeatureStatusSelector(state.vendors.features.features),
});

const mapDispatchToProps = {
  loadDispatchOpportunitiesCount,
  loadFeatures,
  showNotificationIfNewReleaseNotes,
  loadVehicleTypesForVendor,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavigationBar));
