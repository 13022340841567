// import React from 'react';
// import { useSelector } from 'react-redux';

// import { AppState } from '../../store';
import { SocialMediaAreaWrapper, TwitterFeedAreaWrapper } from './styled/News';
import { SocialMediaLinks, SocialPulsePost } from './';

export default function TwitterPosts() {

  // const twitterPosts = useSelector((state: AppState) => state.account.news.twitterPosts);
  // const firstTwitterPost = twitterPosts[0];

  // this is only for migration, once twitter feed is connected to new routeware account we will remove this code
  const firstTwitterPost = {}

  return (
    <SocialMediaAreaWrapper>
      <SocialMediaLinks />
      {
        firstTwitterPost
        && Object.keys(firstTwitterPost).length > 0
        && (
          <TwitterFeedAreaWrapper>
            <SocialPulsePost post={firstTwitterPost} />
          </TwitterFeedAreaWrapper>
        )
      }

    </SocialMediaAreaWrapper>
  );
}
