import { Fragment } from 'react';

// import { useSelector } from 'react-redux';
// import { AppState } from '../../store';

import blogPostsData from 'src/account/services/wordpressPosts.json';
import publicPolicyPostsData from 'src/account/services/publicPolicyPosts.json';

import { BlogPost } from './';
import { BlogSection, NewsSection, NewsSectionTitle } from './styled/News';
import translate from '../../core/services/translate';

interface BlogPostType {
  summary: string;
  title: string;
  publishDate: string;
  url: string;
  image: string;
}

export default function BlogPosts() {

  // const blogPosts = useSelector((state: AppState) => state.account.news.blogPosts);
  // const publicPolicyPosts = useSelector((state: AppState) => state.account.news.publicPolicyPosts);

  const blogPosts = blogPostsData as BlogPostType[];
  const publicPolicyPosts = publicPolicyPostsData as BlogPostType[];

  return (
    <Fragment>
      <NewsSection>
        <NewsSectionTitle>{translate('account.newsFeed')}</NewsSectionTitle>
        {blogPosts.slice(0, 2).map((blogPost, index) => (
          <BlogPost category="News" key={index} blogPost={blogPost} flip={index % 2 === 0} />
        ))}
      </NewsSection>
      <BlogSection>
        <NewsSectionTitle>{translate('account.publicPolicyUpdates')}</NewsSectionTitle>
        {publicPolicyPosts.slice(0, 2).map((blogPost, index) => (
          <BlogPost key={index} blogPost={blogPost} flip={index % 2 === 0} />
        ))}
      </BlogSection>
    </Fragment>
  );
}
