import { ChangeEvent, PureComponent } from 'react';
import { connect } from 'react-redux';

import { AppState } from 'src/store';
import { Dropdown, TypedField } from 'src/core/components';
import { DropDownFieldProps } from 'src/common/components/DropDownFieldProps';
import translate from 'src/core/services/translate';

interface Props extends DropDownFieldProps {
  dailyReportTypes?: Array<{
    technicalName: string;
    name: string;
    id: any;
  }>;
}

class DailyReportTypeDropdown extends PureComponent<Props> {
  onChange = (_e: ChangeEvent<HTMLInputElement>, value: string) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      label,
      withLabel,
      placeholder,
      withPlaceholder,
      dailyReportTypes = [],
      dropdownProps,
    } = this.props;

    const dailyReportTypeOptions = dailyReportTypes.map(reportType => ({
      label: reportType.name,
      value: reportType.technicalName,
    }));

    return (
      <TypedField
        name={name}
        component={Dropdown}
        onChange={this.onChange}
        props={{
          label: label || (withLabel ? translate('insights.report') : undefined),
          placeholder: placeholder || (withPlaceholder ? translate('insights.report') : undefined),
          options: dailyReportTypeOptions,
          ...dropdownProps,
        }}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  dailyReportTypes: state.insights.dailyReportTypes.dailyReportTypes,
});

export default connect(mapStateToProps)(DailyReportTypeDropdown);
